import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, User, Building2, Phone, BriefcaseIcon, Mail } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import api from '../../api/axios';
import { toast } from 'react-hot-toast';

export default function ProfilePage() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [stats, setStats] = useState({
    totalReservations: 0,
    pendingReservations: 0,
    upcomingReservations: 0,
    cancelRequestedReservations: 0,
    cancelledReservations: 0,
    completedReservations: 0
  });

  useEffect(() => {
    fetchUserInfo();
    fetchReservationStats();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get('/api/user/my-info/');
      setUserInfo(response.data);
    } catch (error) {
      toast.error('사용자 정보를 불러오는데 실패했습니다.');
    }
  };

  const fetchReservationStats = async () => {
    try {
      const response = await api.get('/api/user/my-reservations/');
      const { tours, meetings } = response.data;
      const allReservations = [...tours, ...meetings];
      
      // 미국 시간 기준 유지
      const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
   
      // 전체 예약 수
      const totalCount = allReservations.length;
   
      // 상태별 카운트를 reduce로 한 번에 처리
      const counts = allReservations.reduce((acc, reservation) => {
        switch (reservation.status) {
          case 'PENDING':
            acc.pending++;
            break;
          case 'APPROVED':
            // APPROVED 상태의 경우 날짜를 확인하여 upcoming 또는 completed로 분류
            const eventDate = new Date(
              reservation.available_tour 
                ? reservation.available_tour.event_date.date 
                : reservation.event_date.date
            );
            
            const eventTime = reservation.available_tour 
              ? reservation.available_tour.time 
              : reservation.time_range.split('~')[0].trim();
              
            const [hours, minutes] = eventTime.split(':');
            eventDate.setHours(parseInt(hours), parseInt(minutes));
            
            if (eventDate > now) {
              acc.upcoming++;
            } else {
              acc.completed++;
            }
            break;
          case 'COMPLETED':
            acc.completed++;
            break;
          case 'CANCEL_REQUESTED':
            acc.cancelRequested++;
            break;
          case 'CANCELLED':
          case 'REJECTED':
            acc.cancelled++;
            break;
        }
        return acc;
      }, {
        pending: 0,
        upcoming: 0,
        completed: 0,
        cancelRequested: 0,
        cancelled: 0
      });
  
      setStats({
        totalReservations: totalCount,
        pendingReservations: counts.pending,
        upcomingReservations: counts.upcoming,
        completedReservations: counts.completed,
        cancelRequestedReservations: counts.cancelRequested,
        cancelledReservations: counts.cancelled
      });
  
    } catch (error) {
      console.error('Failed to fetch reservation stats:', error);
      toast.error('예약 현황을 불러오는데 실패했습니다.');
    }
  };

  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
  
    try {
      await api.post('/api/user/logout/');
      sessionStorage.clear();
      navigate('/', { replace: true });
    } catch (error) {
      toast.error('로그아웃에 실패했습니다.');
      // 에러가 발생하더라도 로컬의 세션은 클리어하고 로그인 페이지로 이동
      sessionStorage.clear();
      navigate('/', { replace: true });
    }
  };

  if (!userInfo) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-6 h-6 border-2 rounded-full border-sky-600 animate-spin border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      {/* Header */}
      <header className="flex items-center mb-6">
        <button
          onClick={() => navigate(-1)}
          className="p-2 mr-2 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-bold">내 정보</h1>
      </header>

      {/* Profile Summary */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <User className="w-5 h-5" />
            프로필
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="divide-y">
            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">이름</div>
                <div className="mt-1 font-medium">{userInfo.name}</div>
              </div>
              <User className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">회사</div>
                <div className="mt-1 font-medium">{userInfo.company_name}</div>
              </div>
              <Building2 className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">직책</div>
                <div className="mt-1 font-medium">{userInfo.position}</div>
              </div>
              <BriefcaseIcon className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">연락처</div>
                <div className="mt-1 font-medium">{userInfo.phone}</div>
              </div>
              <Phone className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">이메일</div>
                <div className="mt-1 font-medium">{userInfo.email}</div>
              </div>
              <Mail className="w-5 h-5 text-gray-400" />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Reservation Stats */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>예약 현황</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-4">
            <div className="text-center">
              <div className="text-2xl font-bold text-sky-600">
                {stats.totalReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">전체</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-yellow-500">
                {stats.pendingReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">대기</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-green-500">
                {stats.upcomingReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">예정</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-orange-500">
                {stats.cancelRequestedReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">취소요청</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-red-500">
                {stats.cancelledReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">취소/거절</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-blue-500">
                {stats.completedReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">완료</div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Logout Button */}
      <button
        onClick={handleLogout}
        className="w-full py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
      >
        로그아웃
      </button>
    </div>
  );
}
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, Mail, User, Building2, Phone, BriefcaseIcon } from 'lucide-react';
import api from '../../api/axios';
import logoImage from '../../assets/logo.png';
import { toast } from 'react-hot-toast';

export default function RegisterPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    verificationCode: '',
    name: '',
    phone: '',
    company_name: '',
    position: '',
  });

  // 이메일 인증 코드 요청
  const handleRequestVerification = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/user/register/', {
        email: formData.email
      });
      toast.success('인증 코드가 이메일로 전송되었습니다.');
      setStep(2);
    } catch (error) {
      if (error.response?.data?.error === 'Email already exists') {
        toast.error('이미 등록된 이메일입니다.');
      } else {
        toast.error('인증 코드 전송에 실패했습니다.');
      }
    }
  };

  // 인증 코드 확인
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/user/verify-email/', {
        email: formData.email,
        token: formData.verificationCode
      });
      toast.success('이메일이 인증되었습니다.');
      setStep(3);
    } catch (error) {
      toast.error('잘못된 인증 코드입니다.');
    }
  };

  // 최종 회원가입
  const handleCompleteRegistration = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/user/complete-registration/', formData);
      toast.success('회원가입이 완료되었습니다.');
      // 로그인 페이지로 이동
      navigate('/', { replace: true });
    } catch (error) {
      toast.error('회원가입에 실패했습니다.');
    }
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[^\d-]/g, '');
    const numbers = value.replace(/-/g, '');
    
    if (numbers.length <= 11) {
      let formattedNumber = '';
      if (numbers.length > 3) {
        formattedNumber += numbers.substring(0, 3) + '-';
        if (numbers.length > 7) {
          formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
        } else {
          formattedNumber += numbers.substring(3);
        }
      } else {
        formattedNumber = numbers;
      }
      setFormData(prev => ({ ...prev, phone: formattedNumber }));
    }
  };

  return (
    <div className="h-full min-h-screen bg-white">
      <main className="flex justify-center w-full h-full min-h-screen">
        {/* 왼쪽 로고 섹션 - 모바일에서는 숨김 */}
        <div className='flex-col justify-center hidden w-full h-full max-w-2xl min-h-screen lg:flex'>
          <img 
            src={logoImage} 
            alt='logo' 
            className='object-center w-auto h-40 mx-auto'
          />
        </div>

        {/* 회원가입 폼 섹션 */}
        <div className="z-50 w-full h-full max-w-2xl min-h-screen bg-white lg:shadow-xl">
          <div className="flex items-center justify-center w-full h-full min-h-screen p-6 lg:p-8 lg:ml-5">
            <div className="w-full max-w-md space-y-8">
              {/* 헤더 */}
              <div className="flex items-center justify-between">
                <button
                  onClick={() => navigate(-1)}
                  className="p-2 rounded-full hover:bg-gray-100"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <span className="text-lg font-medium">회원가입</span>
                <div className="w-10" /> {/* 균형을 맞추기 위한 빈 공간 */}
              </div>

              {/* 진행 상태 표시 */}
              <div className="flex justify-between">
                <div className={`flex-1 h-1 ${step >= 1 ? 'bg-sky-700' : 'bg-gray-200'}`} />
                <div className={`flex-1 h-1 ${step >= 2 ? 'bg-sky-700' : 'bg-gray-200'}`} />
                <div className={`flex-1 h-1 ${step >= 3 ? 'bg-sky-700' : 'bg-gray-200'}`} />
              </div>

              {/* Step 1: 이메일 입력 */}
              {step === 1 && (
                <form onSubmit={handleRequestVerification} className="space-y-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">이메일</label>
                    <div className="flex items-center space-x-2">
                      <Mail className="w-5 h-5 text-gray-400" />
                      <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className="flex-1 block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="example@company.com"
                        required
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
                  >
                    인증 코드 받기
                  </button>
                </form>
              )}

              {/* Step 2: 인증 코드 확인 */}
              {step === 2 && (
                <form onSubmit={handleVerifyCode} className="space-y-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">인증 코드</label>
                    <input
                      type="text"
                      value={formData.verificationCode}
                      onChange={(e) => setFormData(prev => ({ ...prev, verificationCode: e.target.value }))}
                      className="block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="6자리 인증 코드 입력"
                      maxLength={6}
                      required
                    />
                  </div>
                  <div className="py-1 text-center text-gray-500">
                    *인증메일을 받으시는데 약 2분정도 소요되실 수 있습니다.<br></br>인증메일이 안보이시면 스팸메일함을 확인해주세요. 
                  </div>
                  <button
                    type="submit"
                    className="w-full py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
                  >
                    인증 확인
                  </button>
                </form>
              )}

              {/* Step 3: 회원 정보 입력 */}
              {step === 3 && (
                <form onSubmit={handleCompleteRegistration} className="space-y-6">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">이름</label>
                      <div className="flex items-center space-x-2">
                        <User className="w-5 h-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.name}
                          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                          className="flex-1 block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">전화번호</label>
                      <div className="flex items-center space-x-2">
                        <Phone className="w-5 h-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.phone}
                          onChange={handlePhoneChange}
                          className="flex-1 block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="010-0000-0000"
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">회사명</label>
                      <div className="flex items-center space-x-2">
                        <Building2 className="w-5 h-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.company_name}
                          onChange={(e) => setFormData(prev => ({ ...prev, company_name: e.target.value }))}
                          className="flex-1 block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">직책</label>
                      <div className="flex items-center space-x-2">
                        <BriefcaseIcon className="w-5 h-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.position}
                          onChange={(e) => setFormData(prev => ({ ...prev, position: e.target.value }))}
                          className="flex-1 block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
                  >
                    가입 완료
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
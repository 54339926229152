import React, { useState, useMemo, useEffect } from 'react';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import { Search, RefreshCw, Clock, FileDown } from 'lucide-react';
import { toast } from 'react-hot-toast';
import api from '../../api/axios';
import AdminHeader from '../../components/admin/layout/AdminHeader';
import ReservationDetailModal from '../../components/admin/dashboard/ReservationDetailModal';

const AdminDashboardPage = () => {
  const {
    dates,
    docents,
    rooms,
    approvedReservations,
    completedReservations,
    cancelledReservations,
    stats,
    loading,
    error,
    lastUpdated,
    refreshData
  } = useAdminDashboard(30000); // 30초마다 업데이트

  const [selectedDate, setSelectedDate] = useState('all');
  const [view, setView] = useState('all');
  const [statusFilter, setStatusFilter] = useState('active');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [lastUpdatedText, setLastUpdatedText] = useState('');

  // 에러 발생시 toast 메시지 표시
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // 마지막 업데이트 시간 실시간 업데이트
  useEffect(() => {
    if (!lastUpdated) {
      setLastUpdatedText('');
      return;
    }

    const updateText = () => {
      const now = new Date();
      const diff = Math.floor((now - lastUpdated) / 1000);
      
      if (diff < 60) {
        setLastUpdatedText(`${diff}초 전 업데이트`);
      } else {
        setLastUpdatedText(`${lastUpdated.toLocaleTimeString()} 업데이트`);
      }
    };

    updateText();
    const intervalId = setInterval(updateText, 1000);
    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  const handleExcelDownload = async () => {
    try {
      let params = new URLSearchParams();
      if (selectedDate !== 'all') {
        params.append('start_date', selectedDate);
        params.append('end_date', selectedDate);
      }
  
      const response = await api.get(`/api/admin/reservations/export/?${params}`, {
        responseType: 'blob'
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `예약목록_${new Date().toISOString().split('T')[0]}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
  
      toast.success('엑셀 파일이 다운로드되었습니다.');
    } catch (error) {
      console.error('Error downloading excel:', error);
      toast.error('엑셀 파일 다운로드 중 오류가 발생했습니다.');
    }
  };

  const handleCancelByAdmin = async (reservation, reason) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: 'cancel',
        reason
      });
      toast.success('예약이 취소되었습니다.');
      refreshData();
    } catch (error) {
      console.error('Error cancelling reservation:', error);
      toast.error('예약 취소 중 오류가 발생했습니다.');
    }
  };

  // 예약 시간에 따라 해당하는 시간대 컬럼을 찾는 함수
  const getTimeSlotForReservation = (reservation, type) => {
    if (type === 'tour') {
      // 투어의 경우
      return `${parseInt(reservation.available_tour.time.split(':')[0]).toString().padStart(2, '0')}:00`;
    } else {
      // 미팅룸의 경우
      const firstSlot = reservation.time_slots[0];
      return `${parseInt(firstSlot.available_room.time.split(':')[0]).toString().padStart(2, '0')}:00`;
    }
  };

  // 시간대
  const timeSlots = useMemo(() => {
    return Array.from({ length: 9 }, (_, i) => {
      const hour = 9 + i;
      return `${hour.toString().padStart(2, '0')}:00`;
    });
  }, []);

  // 검색 및 상태 필터링
  const filteredReservations = useMemo(() => {
    let filtered = {
      active: {},
      cancelled: cancelledReservations
    };

    // approved 예약 병합
    Object.keys(approvedReservations).forEach(date => {
      filtered.active[date] = {
        tours: [...(approvedReservations[date]?.tours || [])],
        meetings: [...(approvedReservations[date]?.meetings || [])]
      };
    });

    // completed 예약 병합
    Object.keys(completedReservations).forEach(date => {
      if (!filtered.active[date]) {
        filtered.active[date] = { tours: [], meetings: [] };
      }
      filtered.active[date].tours = [
        ...(filtered.active[date].tours),
        ...(completedReservations[date]?.tours || [])
      ];
      filtered.active[date].meetings = [
        ...(filtered.active[date].meetings),
        ...(completedReservations[date]?.meetings || [])
      ];
    });

    // 날짜 필터링
    if (selectedDate !== 'all') {
      const filterByDate = (reservations) => {
        const filtered = {};
        filtered[selectedDate] = reservations[selectedDate] || { tours: [], meetings: [] };
        return filtered;
      };

      filtered = {
        active: filterByDate(filtered.active),
        cancelled: filterByDate(cancelledReservations)
      };
    }
  
    // 검색어 필터링
    if (searchTerm) {
      const filterBySearchTerm = (reservations) => {
        const filtered = {};
        Object.entries(reservations || {}).forEach(([date, dayData]) => {
          filtered[date] = {
            tours: dayData.tours.filter(tour =>
              tour.visitor.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.visitor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.visitor.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.user.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tour.user.position.toLowerCase().includes(searchTerm.toLowerCase())
            ),
            meetings: dayData.meetings.filter(meeting =>
              meeting.visitor.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.visitor.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.visitor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.visitor.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.user.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
              meeting.user.position.toLowerCase().includes(searchTerm.toLowerCase())
            )
          };
        });
        return filtered;
      };
  
      filtered = {
        active: filterBySearchTerm(filtered.active),
        cancelled: filterBySearchTerm(cancelledReservations)
      };
    }
  
    // 상태 필터링
    if (statusFilter === 'active') {
      return filtered.active || {};
    } else if (statusFilter === 'cancelled') {
      return filtered.cancelled || {};
    }
  
    // 전체 예약을 보여줄 때는 날짜별로 데이터 병합
    const mergedReservations = {};
    
    // 모든 날짜에 대해 데이터 구조 초기화
    dates.forEach(date => {
      mergedReservations[date.date] = {
        tours: [],
        meetings: []
      };
    });
  
    // 활성 예약 병합
    Object.entries(filtered.active || {}).forEach(([date, data]) => {
      if (mergedReservations[date]) {
        mergedReservations[date].tours = [...(mergedReservations[date].tours || []), ...data.tours];
        mergedReservations[date].meetings = [...(mergedReservations[date].meetings || []), ...data.meetings];
      }
    });
  
    // 취소된 예약 병합
    Object.entries(filtered.cancelled || {}).forEach(([date, data]) => {
      if (mergedReservations[date]) {
        mergedReservations[date].tours = [...(mergedReservations[date].tours || []), ...data.tours];
        mergedReservations[date].meetings = [...(mergedReservations[date].meetings || []), ...data.meetings];
      }
    });
  
    return mergedReservations;
  }, [approvedReservations, completedReservations, cancelledReservations, searchTerm, statusFilter, selectedDate, dates]);

  const getReservationCard = (reservation, type) => {
    // 시간 계산 유틸리티 함수들
    const calculateSlotEndTime = (time, duration) => {
      const [hours, minutes] = time.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes + duration;
      const endHours = Math.floor(totalMinutes / 60);
      const endMinutes = totalMinutes % 60;
      return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
    };
  
    // 시간 범위 계산
    const getTimeRange = (reservation) => {
      if (type === 'tour') {
        return `${reservation.available_tour.time} - ${calculateSlotEndTime(
          reservation.available_tour.time, 
          reservation.available_tour.duration
        )}`;
      } else {
        const timeSlots = reservation.time_slots;
        const firstSlot = timeSlots[0].available_room.time;
        const lastSlot = timeSlots[timeSlots.length - 1];
        const endTime = calculateSlotEndTime(lastSlot.available_room.time, lastSlot.duration);
        return `${firstSlot} - ${endTime}`;
      }
    };
  
    const timeRange = getTimeRange(reservation);
    const visitor = reservation.visitor;
    const user = reservation.user;
  
    const isCancelled = reservation.status === 'CANCELLED';
    const cardStyle = isCancelled
      ? 'border-gray-300 bg-gray-50 hover:bg-gray-100 text-gray-700'
      : 'border-sky-300 bg-sky-50 hover:bg-sky-100 text-sky-700';
  
    return (
      <button
        onClick={() => setSelectedReservation({
          ...reservation,
          type
        })}
        className={`w-full px-2 py-1 mb-1 text-center transition-colors border rounded ${cardStyle}`}
      >
        <div className="text-xs font-medium">[{timeRange}]</div>
        <div className="text-sm font-medium">
          {type === 'tour' ? visitor.company : visitor.title}
        </div>
        <div className="text-xs text-gray-600">
          {visitor.name} ({visitor.count}명) / {user.name}
        </div>
        {reservation.status === 'CANCEL_REQUESTED' && (
          <div className="text-xs font-medium text-orange-600">취소 요청중</div>
        )}
      </button>
    );
  };

  const EmptySlot = () => (
    <div className="text-xs text-center text-gray-400">예약 없음</div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminHeader />
      
      <main className="max-w-[99%] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* 컨트롤 패널 */}
        <div className="sticky top-0 z-10 flex items-center justify-between p-4 mb-6 bg-white rounded-lg shadow-sm">
          <div className="flex items-center space-x-4">
            {/* 날짜 필터 추가 */}
            <select
              className="border rounded px-3 py-1.5 text-sm"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            >
              <option value="all">모든 날짜</option>
              {dates.map(date => (
                <option key={date.date} value={date.date}>
                  {date.date}
                </option>
              ))}
            </select>

            {/* 예약 종류 필터 */}
            <select
              className="border rounded px-3 py-1.5 text-sm"
              value={view}
              onChange={(e) => setView(e.target.value)}
            >
              <option value="all">전체 보기</option>
              <option value="tour">VIP 투어</option>
              <option value="meeting">미팅룸</option>
            </select>

            {/* 예약 상태 필터 */}
            <select
              className="border rounded px-3 py-1.5 text-sm"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="active">활성 예약만</option>
              <option value="cancelled">취소된 예약만</option>
              <option value="all">모든 예약</option>
            </select>

            {lastUpdated && (
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="w-4 h-4 mr-1" />
                {lastUpdatedText}
              </div>
            )}

            <div className="flex items-center space-x-3 text-sm">
              <div className="px-3 py-1 rounded-full bg-blue-50">
                오늘 투어: {stats.today_tours}건
              </div>
              <div className="px-3 py-1 rounded-full bg-green-50">
                오늘 미팅: {stats.today_meetings}건
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <div className="relative">
              <input
                type="text"
                placeholder="검색..."
                className="border rounded pl-8 pr-3 py-1.5 text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute w-4 h-4 text-gray-400 transform -translate-y-1/2 left-2 top-1/2" />
            </div>
            <button
              onClick={handleExcelDownload}
              className="flex items-center px-3 py-1.5 text-sm text-white bg-green-600 rounded hover:bg-green-700"
            >
              <FileDown className="w-4 h-4 mr-1" />
              엑셀
            </button>
            <button
              onClick={refreshData}
              className={`p-1.5 rounded hover:bg-gray-100 ${loading ? 'animate-spin' : ''}`}
              disabled={loading}
            >
              <RefreshCw className="w-4 h-4" />
            </button>
          </div>
        </div>

        {loading && !lastUpdated ? (
          <div className="flex items-center justify-center h-64">
            <div className="w-8 h-8 border-b-2 border-gray-900 rounded-full animate-spin"></div>
          </div>
        ) : (
          <div className="space-y-8">
            {(selectedDate === 'all' ? dates : dates.filter(d => d.date === selectedDate)).map(date => (
              <div key={date.date} className="p-6 bg-white rounded-lg shadow-sm">
                <h2 className="mb-4 text-xl font-medium text-sky-600">{date.date}</h2>
                <div className="mb-6 border-b-2 border-gray-300"></div>
                
                {/* VIP 투어 섹션 */}
                {(view === 'all' || view === 'tour') && (
                  <div className="mb-8">
                    <h3 className="mb-3 text-base font-medium">VIP 투어</h3>
                    <div className="overflow-x-auto border rounded">
                      <div className="grid grid-cols-[120px_repeat(9,minmax(100px,1fr))]">
                        {/* 헤더 */}
                        <div className="p-2 text-sm font-medium text-center border-b bg-gray-50">분류</div>
                        {timeSlots.map(time => (
                          <div key={time} className="p-2 text-sm font-medium text-center border-b bg-gray-50">
                            {time}
                          </div>
                        ))}
                        
                        {/* 도슨트별 행 */}
                        {docents.map(docent => (
                          <React.Fragment key={docent.id}>
                            <div className="p-2 text-center border-b">
                              <div className="text-sm font-medium">{docent.name}</div>
                              <div className="text-xs text-gray-500">{docent.language}</div>
                            </div>
                            {timeSlots.map(timeSlot => {
                              const reservations = filteredReservations[date.date]?.tours.filter(tour => 
                                getTimeSlotForReservation(tour, 'tour') === timeSlot
                                && tour.available_tour.docent.id === docent.id
                              ) || [];
                              return (
                                <div key={timeSlot} className="p-1.5 border-b">
                                  {reservations.length > 0 
                                    ? reservations.map(reservation => getReservationCard(reservation, 'tour'))
                                    : <EmptySlot />
                                  }
                                </div>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* 미팅룸 섹션 */}
                {(view === 'all' || view === 'meeting') && (
                  <div>
                    <h3 className="mb-3 text-base font-medium">미팅룸</h3>
                    <div className="overflow-x-auto border rounded">
                      <div className="grid grid-cols-[120px_repeat(9,minmax(100px,1fr))]">
                        {/* 헤더 */}
                        <div className="p-2 text-sm font-medium text-center border-b bg-gray-50">회의실</div>
                        {timeSlots.map(time => (
                          <div key={time} className="p-2 text-sm font-medium text-center border-b bg-gray-50">
                            {time}
                          </div>
                        ))}
                        
                        {/* 회의실별 행 */}
                        {rooms.map(room => (
                          <React.Fragment key={room.id}>
                            <div className="p-2 text-center border-b">
                              <div className="text-sm font-medium">{room.name}</div>
                              <div className="text-xs text-gray-500">
                                {room.floor && `${room.floor}층`} {room.size}인실
                              </div>
                            </div>
                            {timeSlots.map(timeSlot => {
                              const reservations = filteredReservations[date.date]?.meetings.filter(meeting => 
                                getTimeSlotForReservation(meeting, 'meeting') === timeSlot
                                && meeting.room.id === room.id
                              ) || [];
                              return (
                                <div key={timeSlot} className="p-1.5 border-b">
                                  {reservations.length > 0 
                                    ? reservations.map(reservation => getReservationCard(reservation, 'meeting'))
                                    : <EmptySlot />
                                  }
                                </div>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* 예약 상세 정보 모달 */}
        <ReservationDetailModal
          isOpen={!!selectedReservation}
          onClose={() => setSelectedReservation(null)}
          reservation={selectedReservation}
          onCancel={handleCancelByAdmin}
          refreshData={refreshData}
        />
      </main>
    </div>
  );
};

export default AdminDashboardPage;
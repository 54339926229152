import { XIcon, User, Users, Clock, Building2, ScrollText, AlertTriangle } from 'lucide-react';
import { Card } from '../../ui/card';
import { format, addMinutes } from 'date-fns';

const ReservationStatus = ({ status }) => {
  const styles = {
    PENDING: 'bg-yellow-100 text-yellow-800 border border-yellow-200',
    APPROVED: 'bg-emerald-100 text-emerald-800 border border-emerald-200',
    REJECTED: 'bg-red-100 text-red-800 border border-red-200',
    CANCEL_REQUESTED: 'bg-orange-100 text-orange-800 border border-orange-200'
  };

  const labels = {
    PENDING: '대기중',
    APPROVED: '승인됨',
    REJECTED: '거절됨',
    CANCEL_REQUESTED: '취소 요청'
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${styles[status] || 'bg-gray-100 text-gray-800 border border-gray-200'}`}>
      {labels[status] || status}
    </span>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-start gap-3">
    <div className="mt-1">
      <Icon className="w-4 h-4 text-gray-500" />
    </div>
    <div>
      <div className="text-sm text-gray-500">{label}</div>
      <div className="font-medium text-gray-900">{value || '-'}</div>
    </div>
  </div>
);

const ActionButtons = ({ status, onApprove, onReject, onCancelRequest, onClose }) => {
  if (status === 'PENDING') {
    return (
      <div className="flex justify-end gap-2">
        <button
          onClick={() => {
            onApprove();
            onClose();
          }}
          className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-emerald-600 hover:bg-emerald-700"
        >
          승인
        </button>
        <button
          onClick={() => {
            onReject();
            onClose();
          }}
          className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
        >
          거절
        </button>
      </div>
    );
  }

  if (status === 'CANCEL_REQUESTED') {
    return (
      <div className="flex justify-end gap-2">
        <button
          onClick={() => {
            onCancelRequest('approve');
            onClose();
          }}
          className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-emerald-600 hover:bg-emerald-700"
        >
          취소 승인
        </button>
        <button
          onClick={() => {
            onCancelRequest('reject');
            onClose();
          }}
          className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
        >
          취소 거절
        </button>
      </div>
    );
  }

  return null;
};

const ReservationDetailModal = ({ 
  isOpen, 
  onClose, 
  reservation,
  onApprove, 
  onReject,
  onCancelRequest 
}) => {
  if (!isOpen || !reservation) return null;

  const getTimeRange = () => {
    if (reservation.type === 'tour') {
      const startTime = reservation.available_tour.time;
      const endTime = addMinutes(
        new Date(`2000-01-01 ${startTime}`), 
        reservation.available_tour.duration
      ).toTimeString().slice(0, 5);
      return `${startTime} ~ ${endTime}`;
    } else {
      const firstSlot = reservation.time_slots[0];
      const lastSlot = reservation.time_slots[reservation.time_slots.length - 1];
      const startTime = firstSlot.available_room.time;
      const lastStartTime = new Date(`2000-01-01 ${lastSlot.available_room.time}`);
      const endTime = addMinutes(lastStartTime, lastSlot.duration).toTimeString().slice(0, 5);
      return `${startTime} ~ ${endTime}`;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-3xl max-h-[90vh] bg-white">
        <div className="flex items-center justify-between p-6 bg-white border-b">
          <div className="flex items-center gap-2">
            <Clock className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">
              {getTimeRange()} 예약 상세
            </h3>
          </div>
          <button 
            onClick={onClose} 
            className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        
        <div className="overflow-y-auto max-h-[calc(90vh-120px)]">
          <div className="p-6">
            <Card className="p-6 bg-white border shadow-sm">
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center gap-2">
                  <span className={`text-sm font-medium px-3 py-1 rounded-full
                    ${reservation.type === 'tour' 
                      ? 'bg-sky-100 text-sky-700' 
                      : 'bg-emerald-100 text-emerald-700'}`}
                  >
                    {reservation.type === 'tour' ? 'VIP 투어' : '미팅룸'}
                  </span>
                  <ReservationStatus status={reservation.status} />
                  {reservation.status === 'CANCEL_REQUESTED' && (
                    <div className="flex items-center gap-1 px-2 py-1 text-sm text-orange-700 bg-orange-100 rounded-full">
                      <AlertTriangle className="w-4 h-4" />
                      <span>취소 요청됨</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid gap-6 mb-6 md:grid-cols-2">
                {/* 신청자 정보 */}
                <div className="p-4 bg-white border rounded-lg">
                  <h4 className="mb-4 text-sm font-semibold text-gray-900">신청자 정보</h4>
                  <div className="space-y-4">
                    <InfoItem icon={User} label="이름" value={reservation.user.name} />
                    <InfoItem icon={Building2} label="회사명" value={reservation.user.company_name} />
                    <InfoItem icon={User} label="직책" value={reservation.user.position} />
                    <InfoItem icon={User} label="연락처" value={reservation.user.phone} />
                  </div>
                </div>

                {/* 방문자 정보 */}
                <div className="p-4 bg-white border rounded-lg">
                  <h4 className="mb-4 text-sm font-semibold text-gray-900">방문자 정보</h4>
                  <div className="space-y-4">
                    <InfoItem 
                      icon={User} 
                      label="현장 컨택 실무자명/직함(방문인원)" 
                      value={`${reservation.visitor.name} (${reservation.visitor.count}명)`} 
                    />
                    {reservation.type === 'tour' ? (
                      <>
                        <InfoItem icon={Building2} label="방문자 대표명/직함" value={reservation.visitor.company} />
                        <InfoItem icon={User} label="현장 컨택 실무자 휴대번호" value={reservation.visitor.phone} />
                        <InfoItem icon={Users} label="도슨트" value={reservation.available_tour.docent.name}/>
                        <InfoItem icon={Clock} label="투어 시간" value={getTimeRange()} />
                      </>
                    ) : (
                      <>
                        <InfoItem icon={ScrollText} label="미팅 목적" value={reservation.visitor.title} />
                        <InfoItem icon={Building2} label="소속" value={reservation.visitor.company} />
                        <InfoItem icon={User} label="연락처" value={reservation.visitor.phone} />
                        <InfoItem icon={Building2} label="미팅룸" value={reservation.room.name} />
                        <InfoItem icon={Clock} label="예약 시간" value={getTimeRange()} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* 요청사항 */}
              {(reservation.note || reservation.cancel_note) && (
                <div className="p-4 mb-4 space-y-4 bg-white border rounded-lg">
                  {reservation.note && (
                    <div className="flex items-start gap-3">
                      <ScrollText className="w-4 h-4 mt-1 text-gray-500" />
                      <div>
                        <div className="text-sm text-gray-500">
                          {reservation.type === 'tour' ? '상세 정보' : '요청사항'}
                        </div>
                        <div className="text-gray-900 whitespace-pre-line">{reservation.note}</div>
                      </div>
                    </div>
                  )}
                  {reservation.cancel_note && (
                    <div className="flex items-start gap-3">
                      <ScrollText className="w-4 h-4 mt-1 text-gray-500" />
                      <div>
                        <div className="text-sm text-gray-500">취소 사유</div>
                        <div className="text-gray-900 whitespace-pre-line">{reservation.cancel_note}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <ActionButtons 
                status={reservation.status}
                onApprove={() => onApprove(reservation)}
                onReject={() => onReject(reservation)}
                onCancelRequest={(action) => onCancelRequest(reservation, action)}
                onClose={onClose}
              />
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ReservationDetailModal;
import AdminHeader from '../../components/admin/layout/AdminHeader';
import UserManagement from '../../components/admin/settings/UserManagement';

export default function AdminUserSettingsPage() {
  return (
    <div className="min-h-screen bg-gray-100">
      <AdminHeader />
      <main className="max-w-[98%] mx-auto py-6 sm:px-6 lg:px-8">
        <UserManagement />
      </main>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { Card } from '../../components/ui/card';
import { RefreshCw, Clock } from 'lucide-react';
import AdminHeader from '../../components/admin/layout/AdminHeader';
import Timetable from '../../components/admin/reservation/Timetable';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { toast } from 'react-hot-toast';

export default function AdminSchedulePage() {
  const [viewMode, setViewMode] = useState('all');
  const [selectedDate, setSelectedDate] = useState('all');
  const [lastUpdatedText, setLastUpdatedText] = useState('');
  
  const { 
    dates, 
    docents,
    rooms,
    approvedReservations,
    completedReservations,
    stats,
    loading,
    error,
    refreshData,
    lastUpdated
  } = useAdminDashboard(30000); // 30초마다 업데이트

  // 마지막 업데이트 시간 실시간 업데이트
  useEffect(() => {
    if (!lastUpdated) {
      setLastUpdatedText('');
      return;
    }

    const updateText = () => {
      const now = new Date();
      const diff = Math.floor((now - lastUpdated) / 1000);
      
      if (diff < 60) {
        setLastUpdatedText(`${diff}초 전 업데이트`);
      } else {
        setLastUpdatedText(`${lastUpdated.toLocaleTimeString()} 업데이트`);
      }
    };

    updateText();
    const intervalId = setInterval(updateText, 1000);
    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  // 에러 발생시 toast 메시지 표시
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // dates가 변경될 때 첫 번째 날짜를 선택
  useEffect(() => {
    if (dates.length > 0 && selectedDate === 'all') {
      setSelectedDate(dates[0].date);
    }
  }, [dates]);

  if (loading && !lastUpdated) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center h-64">
            <div className="w-8 h-8 border-b-2 rounded-full border-sky-600 animate-spin"></div>
          </div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <Card className="p-4 text-center text-red-600 border-red-200 bg-red-50">
            {error}
          </Card>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminHeader />
      <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
        
        {/* 컨트롤 패널 */}
        <Card className="p-4 mb-6">
          <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
            <div className="flex flex-wrap items-center gap-4">
              {/* 날짜 필터 */}
              <div className="flex gap-2 overflow-x-auto">
              {dates.map(date => (
                <button
                  key={date.date}
                  onClick={() => setSelectedDate(date.date)}
                  className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors whitespace-nowrap
                    ${selectedDate === date.date
                    ? 'bg-sky-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  {format(new Date(date.date), 'M월 d일 (E)', { locale: ko })}
                </button>
              ))}
              </div>

              {/* 오늘의 예약 현황 */}
              <div className="flex items-center gap-3 text-sm">
                <div className="px-3 py-1 rounded-full bg-blue-50">
                  오늘 투어: {stats?.today_tours || 0}건
                </div>
                <div className="px-3 py-1 rounded-full bg-green-50">
                  오늘 미팅: {stats?.today_meetings || 0}건
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              {lastUpdated && (
                <div className="flex items-center text-sm text-gray-500">
                  <Clock className="w-4 h-4 mr-1" />
                  {lastUpdatedText}
                </div>
              )}
              <button
                onClick={refreshData}
                className={`p-1.5 rounded hover:bg-gray-100 ${loading ? 'animate-spin' : ''}`}
                disabled={loading}
              >
                <RefreshCw className="w-4 h-4" />
              </button>
            </div>
          </div>
        </Card>

        <Timetable 
          viewMode={viewMode}
          setViewMode={setViewMode}
          dates={dates}
          docents={docents}
          rooms={rooms}
          approvedReservations={approvedReservations}
          completedReservations={completedReservations}
          refreshData={refreshData}
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
        />
      </main>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarDays, Clock, Users } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import PageHeader from '../../components/user/PageHeader';
import StepProgress from '../../components/user/StepProgress';
import NavigationButtons from '../../components/user/NavigationButtons';
import VisitorForm from '../../components/user/VisitorForm';
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function TourReservationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.state || {};

  const [step, setStep] = useState(() => {
    if (initialState.skipTimeSelection && initialState.date && initialState.time) {
        return 5;
    }
    if (initialState.date) return 2;
    return 1;
});

  const [dates, setDates] = useState([]);
  const [docents, setDocents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialState.date || '');
  const [selectedDocent, setSelectedDocent] = useState(null);
  const [availableTours, setAvailableTours] = useState([]);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visitorInfo, setVisitorInfo] = useState({
    company: '',
    name: '',
    phone: '',
    count: 1,
  });
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const [datesResponse, docentsResponse] = await Promise.all([
          api.get('/api/user/event-dates/'),
          api.get('/api/user/docents/')
        ]);

        setDates(datesResponse.data);
        setDocents(docentsResponse.data);

        if (initialState.docentId) {
          const docent = docentsResponse.data.find(
            d => d.id === parseInt(initialState.docentId)
          );
          if (docent) {
            setSelectedDocent(docent);
            if (initialState.skipTimeSelection) {
              setStep(5);
            } else {
              setStep(3);
            }
          }
        }
      } catch (error) {
        toast.error('데이터를 불러오는데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
}, [initialState.docentId, initialState.skipTimeSelection]);

  const isTimeSelectable = (date, time) => {
    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    const [hours, minutes] = time.split(':').map(Number);
    const targetDate = new Date(date);
    targetDate.setHours(hours, minutes, 0, 0);
  
    return targetDate > now;
  };

  const calculateSlotEndTime = (time, duration = 30) => {
    if (!time) return '';
    
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + duration;
    const endHours = Math.floor(totalMinutes / 60);
    const endMinutes = totalMinutes % 60;
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };
  
  const calculateTimeRange = (tour) => {
    if (!tour) return '';
    return `${formatTime(tour.time)} - ${formatTime(tour.end_time)}`;
  };
  
  const calculateTourDuration = (tour) => {
    if (!tour) return 0;
    return tour.duration;
  };

  const fetchAvailableTours = async (date, docent) => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/user/available-tours/', {
        params: {
          date: date,
          docent_id: docent.id
        }
      });

      const sortedTours = response.data.sort((a, b) => {
        return a.time.localeCompare(b.time);
      });

      setAvailableTours(sortedTours);

      if (initialState.time) {
        const tour = sortedTours.find(t => t.time === initialState.time);
        if (tour) {
          setSelectedTour(tour);
        }
      }
    } catch (error) {
      toast.error('가용 시간을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate && selectedDocent) {
      fetchAvailableTours(selectedDate, selectedDocent);
    }
  }, [selectedDate, selectedDocent?.id]);

  // 시간대(정시) 옵션 생성
  const getHourOptions = () => {
    if (!availableTours.length) return [];
    
    const hours = new Set(
      availableTours.map(tour => {
        const [hours] = tour.time.split(':').map(Number);
        return hours;
      })
    );
    
    return Array.from(hours).sort((a, b) => a - b);
  };
  
  // 선택된 시간의 분단위 슬롯 필터링
  const getMinuteSlots = (hour) => {
    return availableTours.filter(tour => {
      const [slotHour] = tour.time.split(':').map(Number);
      return slotHour === hour;
    });
  };

  const canGoNext = () => {
    switch (step) {
      case 1:
        return !!selectedDate;
      case 2:
        return !!selectedDocent;
      case 3:
        return !!selectedHour;
      case 4:
        return !!selectedTour;
      case 5:
        return true;
      default:
        return false;
    }
  };

  const handlePrevious = () => {
    // skipTimeSelection이 true인 경우 대시보드로 돌아가기
    if (initialState.skipTimeSelection) {
      navigate('/dashboard');
      return;
    }
  
    // 기존 로직
    if (step > 1) {
      if (step === 5) {
        setSelectedTour(null);
      } else if (step === 4) {
        setSelectedHour(null);
      } else if (step === 3) {
        setSelectedDocent(null);
      } else if (step === 2) {
        setSelectedDate('');
      }
      setStep(prev => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (canGoNext() && step < 5) {
      setStep(prev => prev + 1);
    }
  };

  const validateVisitorInfo = () => {
    if (!visitorInfo.company.trim()) {
      toast.error('회사명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.name.trim()) {
      toast.error('방문자명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.phone.trim() || visitorInfo.phone.replace(/-/g, '').length !== 11) {
      toast.error('올바른 연락처를 입력해주세요.');
      return false;
    }
    if (!visitorInfo.count || visitorInfo.count < 1 || visitorInfo.count > 30) {
      toast.error('방문 인원은 1명 이상 30명 이하여야 합니다.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateVisitorInfo()) return;
  
    try {
      // available_tour_id 외에도 event_date_id가 API에서 필요할 수 있음
      await api.post('/api/user/reserve-tour/', {
        available_tour_id: selectedTour.id,
        event_date_id: selectedDate.id, // date id 추가
        visitor: visitorInfo,
        note: note.trim()
      });
      
      // 성공 메시지에 이메일 안내 추가
      toast.success(
        'VIP 투어 예약이 신청되었습니다. \n관리자 검토 후 이메일로 승인 여부를 안내드립니다.',
        {
          duration: 5000,
        }
      );
      navigate('/dashboard', { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.error || '예약 신청에 실패했습니다.');
    }
  };

  if (isLoading) {
    return (
      <div className="container max-w-md min-h-screen p-4 mx-auto">
        <div className="flex items-center justify-center h-screen">
          <div className="w-6 h-6 border-2 rounded-full border-t-transparent border-sky-600 animate-spin" />
        </div>
      </div>
    );
  }

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CalendarDays className="w-5 h-5" />
                날짜 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-3">
                {dates.map((date) => (
                  <button
                    key={date.id}
                    onClick={() => setSelectedDate(date)}
                    className={`flex items-center justify-between w-full p-4 text-left border rounded-lg hover:bg-gray-50 ${
                      selectedDate === date.date ? 'border-sky-500 bg-sky-50' : ''
                    }`}
                  >
                    <span className="font-medium">
                      {new Date(date.date).toLocaleDateString()}
                    </span>
                  </button>
                ))}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Users className="w-5 h-5" />
                도슨트 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedDocent ? selectedDocent.id : ''}
                onChange={(e) => {
                  const docent = docents.find(d => d.id === parseInt(e.target.value));
                  setSelectedDocent(docent);
                }}
                className="w-full px-4 py-3 text-lg border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">도슨트를 선택하세요</option>
                {docents.map((docent) => (
                  <option key={docent.id} value={docent.id}>
                    {docent.name} ({docent.language})
                  </option>
                ))}
              </select>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

        case 3:
          const hourOptions = getHourOptions();
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Clock className="w-5 h-5" />
                  시간 선택
                </CardTitle>
                <CardDescription>
                  {selectedDocent.name} ({selectedDocent.language})
                  <p className="mt-1 text-sm text-gray-500">원하시는 시간을 선택해주세요.</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-3 gap-3">
                  {hourOptions.map((hour) => {
                    // 해당 시간대의 마지막 시간으로 체크
                    const formattedLastMinute = `${hour.toString().padStart(2, '0')}:59`;
                    const isPast = !isTimeSelectable(selectedDate, formattedLastMinute);
                    const hasAvailableSlots = getMinuteSlots(hour).length > 0;
                    
                    return (
                      <button
                        key={hour}
                        onClick={() => hasAvailableSlots && !isPast && setSelectedHour(hour)}
                        disabled={!hasAvailableSlots || isPast}
                        className={`
                          flex items-center justify-center w-full p-4 text-center border rounded-lg
                          ${!isPast && hasAvailableSlots ? 'hover:bg-gray-50' : 'opacity-50 bg-gray-100 cursor-not-allowed'}
                          ${selectedHour === hour ? 'border-sky-500 bg-sky-50' : ''}
                          ${isPast ? 'line-through text-gray-400' : ''}
                        `}
                      >
                        <span className="font-medium">
                          {`${hour.toString().padStart(2, '0')}:00`}
                          {isPast && (
                            <span className="block text-xs text-gray-400">
                              지난 시간
                            </span>
                          )}
                        </span>
                      </button>
                    );
                  })}
                </div>
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    currentStep={step}
                    canGoNext={!!selectedHour}
                  />
                </div>
              </CardContent>
            </Card>
          );

          case 4:
            const minuteSlots = getMinuteSlots(selectedHour);
            
            return (
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Clock className="w-5 h-5" />
                    시간 선택
                  </CardTitle>
                  <CardDescription>
                    {selectedDocent.name} - {selectedHour}시
                    <p className="mt-1 text-sm text-gray-500">원하시는 시간을 선택해주세요.</p>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-3">
                    {minuteSlots.map((tour) => {
                      const isPast = !isTimeSelectable(selectedDate, tour.time);
                      return (
                        <button
                          key={tour.id}
                          onClick={() => !isPast && setSelectedTour(tour)}
                          disabled={isPast}
                          className={`
                            flex items-center justify-center w-full p-4 text-center border rounded-lg 
                            ${!isPast ? 'hover:bg-gray-50' : 'opacity-50 bg-gray-100 cursor-not-allowed'} 
                            ${selectedTour?.id === tour.id ? 'border-sky-500 bg-sky-50' : ''}
                            ${isPast ? 'line-through text-gray-400' : ''}
                          `}
                        >
                          <span className="font-medium">
                            <div>{calculateTimeRange(tour)}</div>
                            <div className="text-xs text-gray-500">총 {tour.duration}분</div>
                            {isPast && (
                              <span className="block text-xs text-gray-400">
                                지난 시간
                              </span>
                            )}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                  <div className="mt-6">
                    <NavigationButtons
                      onPrevious={handlePrevious}
                      onNext={handleNext}
                      currentStep={step}
                      canGoNext={!!selectedTour}
                    />
                  </div>
                </CardContent>
              </Card>
            );

     case 5:
      return selectedDocent && selectedTour ? (
         <Card>
           <CardHeader>
             <CardTitle className="flex items-center gap-2">
               <Users className="w-5 h-5" />
               방문자 정보
             </CardTitle>
           </CardHeader>
           <CardContent>
             <div className="p-5 mb-6 border rounded-lg bg-white/50 backdrop-blur-sm">
               <h3 className="mb-3 text-sm font-medium text-gray-800">예약 정보</h3>
               <dl className="grid gap-3">
                 <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                   <dt className="text-sm text-gray-600">
                     <div className="flex items-center gap-1.5">
                       <CalendarDays className="w-4 h-4" />
                       <span>날짜</span>
                     </div>
                   </dt>
                   <dd className="text-sm font-medium justify-self-end">
                     {new Date(selectedDate).toLocaleDateString()}
                   </dd>
                 </div>
                 <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                   <dt className="text-sm text-gray-600">
                     <div className="flex items-center gap-1.5">
                       <Users className="w-4 h-4" />
                       <span>도슨트</span>
                     </div>
                   </dt>
                   <dd className="text-sm font-medium justify-self-end">
                     <div className="text-right">
                       <div>{selectedDocent.name}</div>
                       <div className="text-xs text-gray-500">{selectedDocent.language}</div>
                     </div>
                   </dd>
                 </div>
                 <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                   <dt className="text-sm text-gray-600">
                     <div className="flex items-center gap-1.5">
                       <Clock className="w-4 h-4" />
                       <span>시간</span>
                     </div>
                   </dt>
                   <dd className="text-sm font-medium justify-self-end">
                    <div className="text-right">
                      <div>{calculateTimeRange(selectedTour)}</div>
                      <div className="text-xs text-gray-500">
                        총 {selectedTour?.duration}분
                      </div>
                    </div>
                  </dd>
                 </div>
               </dl>
             </div>

             <VisitorForm 
               visitorInfo={visitorInfo}
               onChange={setVisitorInfo}
               type="tour"
             />
             <div className="mt-4">
              <label className="block mb-1 text-sm font-medium">상세 정보 작성</label>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="w-full h-24 px-4 py-3 border rounded-lg resize-none focus:ring-2 focus:ring-sky-500"
                placeholder="방문자 대표 외 참여자 명단, 기타 참고 사항 등"
              />
             </div>
             <div className="mt-6">
               <NavigationButtons
                 onPrevious={handlePrevious}
                 onNext={handleSubmit}
                 currentStep={step}
                 isLastStep={true}
                 canGoNext={true}
               />
             </div>
           </CardContent>
         </Card>
       ) : null;

     default:
       return null;
   }
 };

 return (
   <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
     <PageHeader 
       title="VIP 투어 예약" 
       onBack={handlePrevious}
     />
     <StepProgress currentStep={step} />
     {renderStepContent()}
   </div>
 );
}
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { RefreshCw, Clock } from 'lucide-react';
import { Card } from '../../components/ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import AdminHeader from '../../components/admin/layout/AdminHeader';
import DashboardStats from '../../components/admin/layout/DashboardStats';
import ReservationList from '../../components/admin/reservation/ReservationList';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import api from '../../api/axios';

export default function AdminDashboardPage() {
  const [rejectDialog, setRejectDialog] = useState({ open: false, reservation: null });
  const [lastUpdatedText, setLastUpdatedText] = useState('');
  
  const { 
    dates, 
    pendingReservations,
    cancelRequests,
    completedReservations,
    rejectedReservations,
    cancelledReservations,
    stats,
    loading,
    error,
    refreshData,
    lastUpdated
  } = useAdminDashboard(30000); // 30초마다 업데이트

  // 마지막 업데이트 시간 실시간 업데이트
  useEffect(() => {
    if (!lastUpdated) {
      setLastUpdatedText('');
      return;
    }

    const updateText = () => {
      const now = new Date();
      const diff = Math.floor((now - lastUpdated) / 1000);
      
      if (diff < 60) {
        setLastUpdatedText(`${diff}초 전 업데이트`);
      } else {
        setLastUpdatedText(`${lastUpdated.toLocaleTimeString()} 업데이트`);
      }
    };

    updateText();
    const intervalId = setInterval(updateText, 1000);
    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  // 에러 발생시 toast 메시지 표시
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleApprove = async (reservation, type) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: 'approve'
      });
      toast.success('예약이 승인되었습니다.');
      refreshData();
    } catch (error) {
      console.error('Error approving reservation:', error);
      toast.error('예약 승인 중 오류가 발생했습니다.');
    }
  };

  const handleComplete = async (reservation) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: 'complete'
      });
      toast.success('예약이 완료 처리되었습니다.');
      refreshData();
    } catch (error) {
      console.error('Error completing reservation:', error);
      toast.error('완료 처리 중 오류가 발생했습니다.');
    }
  };

  const handleReject = async (reservation, reason) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: 'reject',
        reason
      });
      toast.success('예약이 거절되었습니다.');
      setRejectDialog({ open: false, reservation: null });
      refreshData();
    } catch (error) {
      console.error('Error rejecting reservation:', error);
      toast.error('예약 거절 중 오류가 발생했습니다.');
    }
  };

  const handleCancelRequest = async (reservation, action, reason) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: action === 'approve' ? 'approve_cancellation' : 'reject_cancellation',
        reason
      });
      toast.success(`취소 요청이 ${action === 'approve' ? '승인' : '거절'}되었습니다.`);
      refreshData();
    } catch (error) {
      console.error('Error handling cancel request:', error);
      toast.error('취소 요청 처리 중 오류가 발생했습니다.');
    }
  };

  if (loading && !lastUpdated) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center h-64">
            <div className="w-8 h-8 border-b-2 rounded-full border-sky-600 animate-spin"></div>
          </div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <Card className="p-4 text-center text-red-600 border-red-200 bg-red-50">
            {error}
          </Card>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminHeader />
      <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-6 space-y-4">
          {/* 업데이트 상태 및 새로고침 버튼 */}
          <Card className="p-4">
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-600">실시간 데이터 동기화 중</div>
              <div className="flex items-center space-x-4">
                {lastUpdated && (
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="w-4 h-4 mr-1" />
                    {lastUpdatedText}
                  </div>
                )}
                <button
                  onClick={refreshData}
                  className={`p-1.5 rounded hover:bg-gray-100 ${loading ? 'animate-spin' : ''}`}
                  disabled={loading}
                >
                  <RefreshCw className="w-4 h-4" />
                </button>
              </div>
            </div>
          </Card>
          <DashboardStats stats={stats} />
        </div>
        
        <Card className="mb-6">
          <div className="p-4">
            <ReservationList
              dates={dates}
              pendingReservations={pendingReservations}
              cancelRequests={cancelRequests}
              completedReservations={completedReservations}
              rejectedReservations={rejectedReservations}
              cancelledReservations={cancelledReservations}
              onApprove={handleApprove}
              onReject={(reservation) => setRejectDialog({ open: true, reservation })}
              onCancelRequest={handleCancelRequest}
              onComplete={handleComplete}
            />
          </div>
        </Card>

        <Dialog open={rejectDialog.open} onOpenChange={(open) => !open && setRejectDialog(prev => ({ ...prev, open }))}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>예약 거절 사유</DialogTitle>
            </DialogHeader>
            <form onSubmit={(e) => {
              e.preventDefault();
              const reason = e.target.reason.value;
              if (reason) {
                handleReject(rejectDialog.reservation, reason);
              }
            }}>
              <textarea
                name="reason"
                className="w-full p-2 mb-4 border rounded-md"
                rows={4}
                placeholder="거절 사유를 입력해주세요"
                required
              />
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  className="px-4 py-2 text-sm border rounded-md hover:bg-gray-50"
                  onClick={() => setRejectDialog({ open: false, reservation: null })}
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  거절
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </main>
    </div>
  );
}
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
// Admin pages
import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminSettingsPage from './pages/admin/AdminSettingsPage';
import AdminUserSettingsPage from './pages/admin/AdminUserSettingsPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import AdminReservationPage from './pages/admin/AdminReservationPage';
import AdminSchedulePage from './pages/admin/AdminSchedulePage';

// User pages
import LoginPage from './pages/user/LoginPage';
import RegisterPage from './pages/user/RegisterPage';
import DashboardPage from './pages/user/DashboardPage';
import TourReservationPage from './pages/user/TourReservationPage';
import RoomReservationPage from './pages/user/RoomReservationPage';
import MyReservationsPage from './pages/user/MyReservationsPage';
import ProfilePage from './pages/user/ProfilePage';

// Display pages
import RoomDetailPage from './pages/display/RoomDetailPage';
import DisplayMainPage from './pages/display/DisplayMainPage'

// Auth Routes
import { UserRoute } from './components/auth/AuthRoutes';

// 로그인 상태 확인
const PublicRoute = ({ children }) => {
  const isAuthenticated = sessionStorage.getItem('user_id');
  
  // 이미 로그인된 상태면 대시보드로 리다이렉트
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }
  
  return children;
};

export default function App() {
  return (
    <Router>
      <Toaster position="top-right" />
      <Routes>
        {/* 디스플레이 페이지 */}
        <Route path="/display" element={<DisplayMainPage />} />
        <Route path="/display/room/:roomId" element={<RoomDetailPage />} />
        
        {/* 메인 페이지 (로그인) */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route path="/register" element={<RegisterPage />} />

        {/* 사용자 페이지 */}
        <Route path="/dashboard" element={<UserRoute><DashboardPage /></UserRoute>} />
        <Route path="/reserve-tour" element={<UserRoute><TourReservationPage /></UserRoute>} />
        <Route path="/reserve-room" element={<UserRoute><RoomReservationPage /></UserRoute>} />
        <Route path="/reservations" element={<UserRoute><MyReservationsPage /></UserRoute>} />
        <Route path="/profile" element={<UserRoute><ProfilePage /></UserRoute>} />

        {/* 관리자 페이지 */}
        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="/admin/settings" element={<AdminSettingsPage />} />
        <Route path="/admin/users" element={<AdminUserSettingsPage />} />
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/schedule" element={<AdminSchedulePage />} />
        <Route path="/admin/reservation" element={<AdminReservationPage />} />

        {/* 404 리다이렉트 */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

// 개발 환경에서 콘솔 로그 비활성화
if (process.env.NODE_ENV === "production") {
  console = window.console || {};
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function () {};
}
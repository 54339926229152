import React, { useState } from 'react';
import { Card } from '../../ui/card';
import { toast } from 'react-hot-toast';
import { X, Save } from 'lucide-react';
import api from '../../../api/axios';

const VisitorEditForm = ({ 
  visitor, 
  reservationType, 
  reservationId, 
  onSuccess, 
  onCancel,
  maxSize // room.size 또는 30(tour)
}) => {
  const [formData, setFormData] = useState({
    company: visitor.company,
    name: visitor.name,
    phone: visitor.phone,
    count: visitor.count,
    title: reservationType === 'meeting' ? visitor.title : undefined
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'count' ? parseInt(value) || 0 : value
    }));
  };

  const validateForm = () => {
    // 기본 필드 검증
    if (!formData.company?.trim()) {
      toast.error(reservationType === 'tour' ? '방문자 대표명/직함을 입력해주세요.' : '회사명을 입력해주세요.');
      return false;
    }
    if (!formData.name?.trim()) {
      toast.error(reservationType === 'tour' ? '현장 컨택 실무자명/직함을 입력해주세요.' : '방문자명을 입력해주세요.');
      return false;
    }
    if (!formData.phone?.trim()) {
      toast.error(reservationType === 'tour' ? '현장 컨택 실무자 휴대번호를 입력해주세요.' : '연락처를 입력해주세요.');
      return false;
    }
    if (reservationType === 'meeting' && !formData.title?.trim()) {
      toast.error('미팅 목적을 입력해주세요.');
      return false;
    }

    // 방문 인원 검증
    if (formData.count === null || formData.count === undefined) {
      toast.error('방문 인원을 입력해주세요.');
      return false;
    }

    if (formData.count < 1) {
      toast.error('방문 인원은 1명 이상이어야 합니다.');
      return false;
    }

    if (reservationType === 'tour') {
      if (formData.count > 30) {
        toast.error('투어 방문 인원은 30명을 초과할 수 없습니다.');
        return false;
      }
    } else {
      if (formData.count > maxSize) {
        toast.error(`미팅룸 수용 인원(${maxSize}명)을 초과할 수 없습니다.`);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const endpoint = `/api/admin/reservations/${reservationId}/visitor/`;
      await api.patch(endpoint, {
        type: reservationType === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        visitor: formData
      });
      
      toast.success('방문자 정보가 수정되었습니다.');
      onSuccess(formData);
    } catch (error) {
      console.error('Error updating visitor:', error);
      toast.error('방문자 정보 수정 중 오류가 발생했습니다.');
    }
  };

  return (
    <Card className="p-4 border border-gray-200">
      <form onSubmit={handleSubmit} className="space-y-4">
        {reservationType === 'meeting' && (
          <div>
            <label className="block mb-1.5 text-sm text-gray-500">
              미팅 목적
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
              required
            />
          </div>
        )}
        
        <div>
          <label className="block mb-1.5 text-sm text-gray-500">
            {reservationType === 'tour' ? "현장 컨택 실무자명/직함" : "방문자명"}
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block mb-1.5 text-sm text-gray-500">
            {reservationType === 'tour' ? "방문자 대표명/직함" : "회사명"}
          </label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block mb-1.5 text-sm text-gray-500">
            {reservationType === 'tour' ? "현장 컨택 실무자 휴대번호" : "연락처"}
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
            required
            pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
            placeholder="010-0000-0000"
          />
        </div>

        <div>
          <label className="block mb-1.5 text-sm text-gray-500">
            방문 인원
          </label>
          <input
            type="number"
            name="count"
            min="1"
            max={reservationType === 'tour' ? 30 : maxSize}
            value={formData.count}
            onChange={handleChange}
            className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
            required
          />
          <div className="mt-1 text-xs text-gray-500">
            {reservationType === 'tour' 
              ? '최대 30명까지 가능합니다.'
              : maxSize ? `최대 ${maxSize}명까지 가능합니다.` : '방문 인원을 입력해주세요.'}
          </div>
        </div>

        <div className="flex justify-end gap-2 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <span className="flex items-center">
              <X className="w-4 h-4 mr-1.5" />
              취소
            </span>
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white rounded-md bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <span className="flex items-center">
              <Save className="w-4 h-4 mr-1.5" />
              저장
            </span>
          </button>
        </div>
      </form>
    </Card>
  );
};

export default VisitorEditForm;
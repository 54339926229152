import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Clock, Calendar, User } from 'lucide-react';
import api from '../../api/axios';
import { cn } from "../../lib/utils";
import logoImage from '../../assets/logowhite.png';

const mergeConsecutiveReservations = (reservations, timeSlots) => {
  if (!reservations?.length || !timeSlots?.length) return [];
  
  // time_range에서 시작 시간과 종료 시간을 파싱
  const parseTimeRange = (timeRange) => {
    const [start, end] = timeRange.split(' ~ ');
    return { start_time: start, end_time: end };
  };

  // 예약을 시간순으로 정렬
  const sortedReservations = [...reservations].sort((a, b) => {
    const aTime = parseTimeRange(a.time_range).start_time;
    const bTime = parseTimeRange(b.time_range).start_time;
    
    return timeSlots.findIndex(slot => slot.time === aTime) 
           - timeSlots.findIndex(slot => slot.time === bTime);
  });

  const mergedReservations = [];
  let currentGroup = null;

  // 예약자 정보가 같은지 확인하는 함수
  const isSameReservation = (r1, r2) => {
    return r1.visitor.name === r2.visitor.name &&
           r1.visitor.title === r2.visitor.title &&
           r1.status === r2.status;
  };

  // 연속된 시간대인지 확인하는 함수
  const isConsecutiveSlot = (time1, time2) => {
    const index1 = timeSlots.findIndex(slot => slot.time === time1);
    const index2 = timeSlots.findIndex(slot => slot.time === time2);
    return index2 === index1 + 1;
  };

  for (let i = 0; i < sortedReservations.length; i++) {
    const currentReservation = sortedReservations[i];
    const { start_time, end_time } = parseTimeRange(currentReservation.time_range);
    
    if (!currentGroup) {
      // 새 그룹 시작
      currentGroup = {
        ...currentReservation,
        start_time,
        end_time
      };
      continue;
    }

    // 현재 예약이 이전 그룹과 연속되는지 확인
    if (isSameReservation(currentGroup, currentReservation) && 
        isConsecutiveSlot(currentGroup.end_time, start_time)) {
      // 연속되는 경우 종료 시간 업데이트
      currentGroup.end_time = end_time;
    } else {
      // 연속되지 않는 경우 이전 그룹을 저장하고 새 그룹 시작
      mergedReservations.push(currentGroup);
      currentGroup = {
        ...currentReservation,
        start_time,
        end_time
      };
    }
  }

  // 마지막 그룹 추가
  if (currentGroup) {
    mergedReservations.push(currentGroup);
  }

  return mergedReservations;
};

const useRoomStatus = (timeSlots, reservations) => {
  const currentTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  return useMemo(() => {
    if (!timeSlots?.length) return { status: 'AVAILABLE', currentReservation: null, nextReservation: null };

    const getReservationEndTime = (time) => {
      return time;
    };

    const mergedReservations = mergeConsecutiveReservations(reservations, timeSlots);

    // 현재 시간을 "HH:mm" 형식으로 변환
    const currentTimeStr = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;

    // 현재 사용 중인 예약 찾기
    const currentReservation = mergedReservations.find(reservation => {
      const isAfterStart = currentTimeStr >= reservation.start_time;
      const isBeforeEnd = currentTimeStr <= getReservationEndTime(reservation.end_time);
      return isAfterStart && isBeforeEnd && reservation.status === "APPROVED";
    });

    // 다음 예약 찾기
    const nextReservation = mergedReservations.find(reservation => {
      return currentTimeStr < reservation.start_time && reservation.status === "APPROVED";
    });

    // 현재 시간과 주어진 시간 사이의 분 차이 계산
    const getMinutesDifference = (time1, time2) => {
      const [hour1, minute1] = time1.split(':').map(Number);
      const [hour2, minute2] = time2.split(':').map(Number);
      return (hour2 - hour1) * 60 + (minute2 - minute1);
    };

    // 상태 결정 로직
    if (currentReservation) {
      const minutesToEnd = getMinutesDifference(
        currentTimeStr,
        getReservationEndTime(currentReservation.end_time)
      );

      if (minutesToEnd <= 5) {
        return {
          status: 'STARTING_SOON',
          currentReservation: {
            ...currentReservation,
            minutesToEnd
          },
          nextReservation
        };
      }
      
      return {
        status: 'IN_USE',
        currentReservation: {
          ...currentReservation,
          minutesToEnd
        },
        nextReservation
      };
    }

    if (nextReservation) {
      const minutesToStart = getMinutesDifference(
        currentTimeStr,
        nextReservation.start_time
      );

      return {
        status: minutesToStart <= 10 ? 'STARTING_SOON' : 'AVAILABLE',
        currentReservation: null,
        nextReservation: {
          ...nextReservation,
          minutesToStart
        }
      };
    }

    return { 
      status: 'AVAILABLE', 
      currentReservation: null, 
      nextReservation: null 
    };
  }, [timeSlots, reservations, currentHour, currentMinute]);
};

const RoomDetailPage = () => {
  const { roomId } = useParams();
  const [prevStatus, setPrevStatus] = useState('AVAILABLE');
  const [roomData, setRoomData] = useState(null);
  const [currentTime, setCurrentTime] = useState(
    new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { status, currentReservation, nextReservation } = useRoomStatus(
    roomData?.time_slots,
    roomData?.reservations || []
  );

  const getErrorMessage = (error) => {
    if (!error?.response) {
      return '서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.';
    }

    const errorCode = error.response.data?.code;
    switch (errorCode) {
      case 'room_not_found':
        return '존재하지 않는 회의실입니다.';
      case 'no_schedule_data':
        return '설정된 행사기간이 아닙니다.';
      case 'server_error':
        return '회의실 정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.';
      default:
        return '회의실 정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.';
    }
  };

  useEffect(() => {
    if (status !== 'STARTING_SOON') {
      setPrevStatus(status);
    }
  }, [status]);

  const getTimeRemaining = useCallback((endTime) => {
    const [endHour, endMinute] = endTime.split(':').map(Number);
    // currentTime state를 사용하여 LA 시간대 기준으로 계산
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    
    // 음수가 되지 않도록 처리
    const minutesDiff = (endHour - currentHour) * 60 + (endMinute - currentMinute);
    return Math.max(0, minutesDiff);
  }, [currentTime]);

  const slotDuration = useMemo(() => {
    if (!roomData?.time_slots?.length) return 0;
    const slot1 = roomData.time_slots[0].time;
    const slot2 = roomData.time_slots[1].time;
    const [hour1, minute1] = slot1.split(':').map(Number);
    const [hour2, minute2] = slot2.split(':').map(Number);
    return (hour2 - hour1) * 60 + (minute2 - minute1);
  }, [roomData?.time_slots]);

  const getTimeWithOffset = useCallback((time, minutes) => {
    const [hours, mins] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + mins + minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMins = totalMinutes % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMins).padStart(2, '0')}`;
  }, []);

  const getReservationEndTime = useCallback((time) => {
    if (!roomData?.time_slots) return time;
    const baseInterval = slotDuration;
    return getTimeWithOffset(time, baseInterval);
  }, [roomData?.time_slots, slotDuration, getTimeWithOffset]);

  const fetchRoomData = useCallback(async () => {
    try {
      if (isInitialLoad) {
        setLoading(true);
      }
      
      const response = await api.get(`/api/display/rooms/${roomId}/`);
      
      setRoomData(prevData => {
        if (JSON.stringify(prevData) === JSON.stringify(response.data)) {
          return prevData;
        }
        return response.data;
      });
      
      setError(null);
    } catch (err) {
      if (isInitialLoad) {
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
      }
    } finally {
      if (isInitialLoad) {
        setIsInitialLoad(false);
        setLoading(false);
      }
    }
  }, [roomId, isInitialLoad]);

  useEffect(() => {
    fetchRoomData();
    const interval = setInterval(fetchRoomData, 30000);
    return () => clearInterval(interval);
  }, [fetchRoomData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })));
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const getErrorScreenClassName = () => {
    if (!error) return '';
    
    if (error.includes('설정된 행사기간이 아닙니다.')) {
      return 'bg-green-600'; // 스케줄 데이터 없음
    }
    if (error.includes('존재하지 않는 회의실입니다')) {
      return 'bg-gray-600'; // 회의실 없음
    }
    return 'bg-red-600'; // 기타 서버 오류
  };

  if (error) return (
    <div className={`flex items-center justify-center w-full h-screen ${getErrorScreenClassName()}`}>
      <div className="max-w-lg px-6 py-4 text-center">
        <div className="text-2xl font-bold text-white">{error}</div>
        {error.includes('설정된 행사기간이 아닙니다.') && (
          <div className="mt-4 text-lg text-white/80">
            설정된 행사 기간에는 정상적으로 이용 가능합니다.
          </div>
        )}
      </div>
    </div>
  );

  const TimelineSection = () => {
    const timelineRef = useRef(null);

    useEffect(() => {
      if (timelineRef.current && roomData?.time_slots) {
        const currentTimePosition = getCurrentTimePosition();
        timelineRef.current.scrollTop = Math.max(0, currentTimePosition - 200);
      }
    }, [roomData]);

    if (loading && isInitialLoad) {
      return (
        <div className="flex items-center justify-center w-full h-screen bg-gray-900">
          <div className="text-2xl text-white">Loading...</div>
        </div>
      );
    }

    if (error && isInitialLoad) {
      return (
        <div className="flex items-center justify-center w-full h-screen bg-red-900">
          <div className="text-2xl text-white">{error}</div>
        </div>
      );
    }

    const getTimelineHeight = () => {
      if (!roomData?.time_slots?.length) return 0;
      return roomData.time_slots.length * 128;
    };

    const getSlotIndexFromTime = (time) => {
      const [targetHour, targetMinute] = time.split(':').map(Number);
      const targetMinutes = targetHour * 60 + targetMinute;
      
      return roomData.time_slots.findIndex((slot, index) => {
        const [slotHour, slotMinute] = slot.time.split(':').map(Number);
        const slotMinutes = slotHour * 60 + slotMinute;
        const nextSlot = roomData.time_slots[index + 1];
        
        if (!nextSlot) return true;
        
        const [nextHour, nextMinute] = nextSlot.time.split(':').map(Number);
        const nextSlotMinutes = nextHour * 60 + nextMinute;
        
        return targetMinutes >= slotMinutes && targetMinutes < nextSlotMinutes;
      });
    };

    const getCurrentTimePosition = () => {
      const currentTimeString = currentTime.toLocaleTimeString('ko-KR', { 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: false 
      });
      
      const slotIndex = getSlotIndexFromTime(currentTimeString);
      if (slotIndex === -1) return 0;
      
      const currentSlot = roomData.time_slots[slotIndex];
      const [slotHour, slotMinute] = currentSlot.time.split(':').map(Number);
      const [currentHour, currentMinute] = currentTimeString.split(':').map(Number);
      
      const minutesFromSlotStart = (currentHour - slotHour) * 60 + (currentMinute - slotMinute);
      const progressInSlot = minutesFromSlotStart / slotDuration;
      
      return (slotIndex + progressInSlot) * 128;
    };

    const ReservationCard = ({ reservation, startIndex, height }) => {
      const cardHeight = height;
      const contentPosition = Math.max(0, (cardHeight - 100) / 2);

      return (
        <div
          className="absolute left-0 right-0 px-4"
          style={{
            top: `${startIndex * 128}px`,
            height: `${cardHeight}px`,
          }}
        >
          <div 
            className="relative h-full bg-white/10"
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(255,255,255,0.05) 1px, transparent 1px)`,
              backgroundSize: '100% 128px'
            }}
          >
            <div 
              className="absolute left-4 right-4"
              style={{ top: `${contentPosition}px` }}
            >
              <div className="text-lg font-medium text-white">
                {reservation.visitor.title}
              </div>
              <div className="text-sm text-white/70">
                {reservation.visitor.name}
              </div>
              <div className="mt-1 text-sm text-white/80">
                {reservation.start_time} - {reservation.end_time}
              </div>
           </div>
         </div>
       </div>
     );
   };

   return (
     <div className="h-screen w-80 bg-black/20">
       <div ref={timelineRef} className="relative h-full overflow-y-auto">
         <div className="relative" style={{ height: `${getTimelineHeight()}px`, marginTop: "50px" }}>
           {/* Time Axis */}
           <div className="absolute left-0 w-16">
             {roomData?.time_slots.map((slot, idx) => (
               <div 
                 key={idx} 
                 className="absolute flex items-center justify-center w-full"
                 style={{
                   top: `${idx * 128 - 64}px`,
                   height: '128px'
                 }}
               >
                 <span className="text-sm text-gray-400">{slot.time}</span>
               </div>
             ))}
           </div>

           {/* Reservations */}
           <div className="absolute right-0 left-20">
             {mergeConsecutiveReservations(roomData?.reservations || [], roomData?.time_slots || [])
               .map((reservation, index) => {
                 const startIndex = roomData.time_slots.findIndex(
                   slot => slot.time === reservation.start_time
                 );
                 const endIndex = roomData.time_slots.findIndex(
                   slot => slot.time === reservation.end_time
                 );
                 const height = (endIndex - startIndex) * 128;

                 return (
                   <ReservationCard
                     key={index}
                     reservation={reservation}
                     startIndex={startIndex}
                     height={height}
                   />
                 );
               })}
           </div>

           {/* Current Time Line */}
           <div 
             className="absolute left-0 right-0 z-20 flex items-center gap-2"
             style={{ top: `${getCurrentTimePosition()-13}px` }}
           >
             <div className="w-20 text-center">
               <span className="text-lg font-bold text-white">
                 {currentTime.toLocaleTimeString('ko-KR', { 
                   hour: '2-digit', 
                   minute: '2-digit',
                   hour12: false 
                 })}
               </span>
             </div>
             <div className="flex-1 h-px bg-white" />
           </div>
         </div>
       </div>
     </div>
   );
 };

 if (loading) return (
   <div className="flex items-center justify-center w-full h-screen bg-gray-900">
     <div className="text-2xl text-white">Loading...</div>
   </div>
 );

 if (error) return (
   <div className="flex items-center justify-center w-full h-screen bg-red-900">
     <div className="text-2xl text-white">{error}</div>
   </div>
 );

 const statusConfig = {
   'AVAILABLE': { bg: 'bg-green-600', text: 'Available' },
   'STARTING_SOON': { 
     bg: 'bg-orange-500', 
     getText: (prevStatus) => prevStatus === 'AVAILABLE' ? 'Starting Soon' : 'End Soon'
   },
   'IN_USE': { bg: 'bg-customRed', text: 'In Use' }
 };

 return (
   <div className={cn("h-screen overflow-hidden", statusConfig[status].bg)}>
     <div className="flex h-full">
       <div className="flex-1 p-8">
         <div className="mb-6">
           <img src={logoImage} alt="Logo" className="h-14" />
         </div>
         <div className="flex items-center justify-center h-[calc(100%-5rem)]">
           <div className="w-full max-w-5xl 2xl:max-w-7xl">
             <div className="pl-12 mb-12">
               <h3 className="mt-5 mb-2 text-xl font-semibold text-white 2xl:text-5xl">{roomData?.room_info.name}</h3>
               <div className="mt-10 text-6xl font-bold text-white 2xl:text-9xl">
                 {status === 'STARTING_SOON' 
                   ? statusConfig[status].getText(prevStatus)
                   : statusConfig[status].text
                 }
               </div>
             </div>
             <div className="pl-12 space-y-6 text-white lg:space-y-8 2xl:space-y-10">
               {(currentReservation || nextReservation) && (
                 <>
                   <div className="flex items-center space-x-4 lg:space-x-6">
                     <Calendar className="w-6 h-6 2xl:w-10 2xl:h-10" />
                     <span className="text-xl 2xl:text-3xl">
                       {currentReservation?.visitor?.title || nextReservation?.visitor?.title || 'No title'}
                     </span>
                   </div>
                   <div className="flex items-center space-x-4 lg:space-x-6">
                     <User className="w-6 h-6 lg:w-8 2xl:w-10 2xl:h-10" />
                     <span className="text-xl 2xl:text-3xl">
                       {currentReservation?.visitor?.name || nextReservation?.visitor?.name || 'No name'}
                     </span>
                   </div>
                   <div className="flex items-center space-x-4 lg:space-x-6">
                     <Clock className="w-6 h-6 lg:w-8 2xl:w-10 2xl:h-10" />
                     {status === 'AVAILABLE' && nextReservation && (
                       <span className="text-xl 2xl:text-3xl">
                         {nextReservation.minutesToStart} minutes left until next reservation
                       </span>
                     )}
                     {(status === 'STARTING_SOON' || status === 'IN_USE') && (
                       <span className="text-xl 2xl:text-3xl">
                         {currentReservation?.start_time || nextReservation?.start_time} - {
                           currentReservation 
                            ? currentReservation.end_time
                            : nextReservation?.end_time
                         }
                       </span>
                     )}
                   </div>
                 </>
               )}
               {!currentReservation && !nextReservation && (
                 <div className="flex items-center space-x-4 lg:space-x-6">
                   <Clock className="w-6 h-6 lg:w-8 2xl:w-10 xl:h-10" />
                   <span className="text-xl 2xl:text-3xl">No upcoming reservations</span>
                 </div>
               )}
             </div>
             <div className="pl-12 mt-12 2xl:mt-20">
               <div className="inline-block px-4 py-2 text-2xl font-bold text-white border border-white rounded-full xl:text-4xl bg-white/20">
                 {status === 'IN_USE' && `Unavailable for ${getTimeRemaining(currentReservation.end_time)}min`}
                 {status === 'STARTING_SOON' && nextReservation && (prevStatus === 'AVAILABLE' 
                   ? `Starts in ${getTimeRemaining(nextReservation.start_time)}min`
                   : `Meeting ends in ${getTimeRemaining(currentReservation.end_time)}min`)
                 }
                 {status === 'AVAILABLE' && 'The room is empty'}
               </div>
             </div>
           </div>
         </div>
       </div>        
       <TimelineSection />
     </div>
   </div>
 );
};

export default RoomDetailPage;
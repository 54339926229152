import React, { useState } from 'react';
import { Card } from '../../ui/card';
import { toast } from 'react-hot-toast';
import { X, Save } from 'lucide-react';
import api from '../../../api/axios';

const NoteEditForm = ({ 
  note,
  reservationType, 
  reservationId, 
  onSuccess, 
  onCancel
}) => {
  const [formData, setFormData] = useState({
    note: note || ''
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData({ note: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const endpoint = `/api/admin/reservations/${reservationId}/note/`;
      await api.patch(endpoint, {
        type: reservationType === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        note: formData.note
      });
      
      toast.success('추가 정보가 수정되었습니다.');
      onSuccess(formData.note);
    } catch (error) {
      console.error('Error updating note:', error);
      toast.error('추가 정보 수정 중 오류가 발생했습니다.');
    }
  };

  return (
    <Card className="p-4 border border-gray-200">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1.5 text-sm text-gray-500">
            {reservationType === 'tour' ? "상세 정보" : "요청사항"}
          </label>
          <textarea
            name="note"
            value={formData.note}
            onChange={handleChange}
            rows={4}
            className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
          />
        </div>

        <div className="flex justify-end gap-2 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <span className="flex items-center">
              <X className="w-4 h-4 mr-1.5" />
              취소
            </span>
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white rounded-md bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <span className="flex items-center">
              <Save className="w-4 h-4 mr-1.5" />
              저장
            </span>
          </button>
        </div>
      </form>
    </Card>
  );
};

export default NoteEditForm;
import { useState, useMemo } from 'react';
import { format, addMinutes } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Card } from "../../ui/card";
import ReservationDetailModal from './ReservationDetailModal';
import { Clock, Users, AlertTriangle } from 'lucide-react';

const StatusBadge = ({ status }) => {
  const styles = {
    PENDING: 'bg-yellow-100 text-yellow-800',
    CANCEL_REQUESTED: 'bg-orange-100 text-orange-800',
  };
  
  const labels = {
    PENDING: '대기중',
    CANCEL_REQUESTED: '취소 요청',
  };

  return (
    <span className={`px-3 py-1 text-sm font-medium rounded-full ${styles[status]}`}>
      {labels[status]}
    </span>
  );
};

const ReservationCard = ({ reservation, type }) => {
  const startTime = type === 'tour' 
    ? reservation.available_tour.time
    : reservation.time_slots[0].available_room.time;

  const endTime = type === 'tour'
    ? addMinutes(
        new Date(`2000-01-01 ${reservation.available_tour.time}`), 
        reservation.available_tour.duration
      ).toTimeString().slice(0, 5)
    : (() => {
        const lastSlot = reservation.time_slots[reservation.time_slots.length - 1];
        const lastStartTime = new Date(`2000-01-01 ${lastSlot.available_room.time}`);
        return addMinutes(lastStartTime, lastSlot.duration).toTimeString().slice(0, 5);
      })();

  return (
    <div className="flex items-center justify-between p-3 border-b last:border-b-0">
      <div className="flex flex-col">
        <span className="font-medium text-gray-900">
          {reservation.user.name} ({reservation.user.company_name}) <br></br> {reservation.visitor.name} ({reservation.visitor.company})
        </span>
        <span className="text-sm text-gray-500">
          {startTime} ~ {endTime}
          {type === 'tour' && ` • ${reservation.available_tour.docent.name} 도슨트`}
          {type === 'meeting' && ` • ${reservation.room.name}`}
        </span>
      </div>
      <StatusBadge status={reservation.status} />
    </div>
  );
};

const DateCard = ({ 
  date, 
  tours = [], 
  meetings = [], 
  cancelRequests = { tours: [], meetings: [] },
  onApprove, 
  onReject,
  onCancelRequest 
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);

  const allTours = [...tours, ...cancelRequests.tours];
  const allMeetings = [...meetings, ...cancelRequests.meetings];

  return (
    <Card className="mb-6">
      <div className="p-6">
        <h2 className="mb-4 text-lg font-semibold text-gray-900">
          {format(new Date(date), 'M월 d일 (E)', { locale: ko })}
        </h2>
        
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="p-4 border rounded-lg bg-sky-50/50 border-sky-100">
            <div className="flex items-center gap-2 mb-3">
              <Clock className="w-4 h-4 text-sky-600" />
              <h3 className="text-base font-medium text-sky-900">VIP 투어</h3>
            </div>
            <div className="divide-y">
              {allTours.map(tour => (
                <button
                  key={tour.id}
                  onClick={() => {
                    setSelectedReservation({ ...tour, type: 'tour' });
                    setShowModal(true);
                  }}
                  className="block w-full text-left hover:bg-sky-50/50"
                >
                  <ReservationCard reservation={tour} type="tour" />
                </button>
              ))}
              {allTours.length === 0 && (
                <div className="py-3 text-sm text-center text-gray-500">
                  대기 중인 예약이 없습니다.
                </div>
              )}
            </div>
          </div>

          <div className="p-4 border rounded-lg bg-emerald-50/50 border-emerald-100">
            <div className="flex items-center gap-2 mb-3">
              <Users className="w-4 h-4 text-emerald-600" />
              <h3 className="text-base font-medium text-emerald-900">미팅룸</h3>
            </div>
            <div className="divide-y">
              {allMeetings.map(meeting => (
                <button
                  key={meeting.id}
                  onClick={() => {
                    setSelectedReservation({ ...meeting, type: 'meeting' });
                    setShowModal(true);
                  }}
                  className="block w-full text-left hover:bg-emerald-50/50"
                >
                  <ReservationCard reservation={meeting} type="meeting" />
                </button>
              ))}
              {allMeetings.length === 0 && (
                <div className="py-3 text-sm text-center text-gray-500">
                  대기 중인 예약이 없습니다.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ReservationDetailModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedReservation(null);
        }}
        reservation={selectedReservation}
        onApprove={onApprove}
        onReject={onReject}
        onCancelRequest={onCancelRequest}
      />
    </Card>
  );
};

export default function ReservationList({ 
  dates = [], 
  pendingReservations = {}, 
  cancelRequests = {},
  onApprove, 
  onReject,
  onCancelRequest 
}) {
  const [selectedDate, setSelectedDate] = useState('all');

  const datesWithReservations = useMemo(() => {
    return dates.filter(date => {
      const dateReservations = pendingReservations[date.date];
      const dateCancelRequests = cancelRequests[date.date];
      return (
        (dateReservations?.tours?.length > 0 || dateReservations?.meetings?.length > 0) ||
        (dateCancelRequests?.tours?.length > 0 || dateCancelRequests?.meetings?.length > 0)
      );
    });
  }, [dates, pendingReservations, cancelRequests]);

  if (datesWithReservations.length === 0) {
    return (
      <Card className="p-6">
        <div className="py-8 text-center text-gray-500">
          처리할 예약이 없습니다.
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="p-4">
        <div className="flex gap-2 overflow-x-auto">
          <button
            onClick={() => setSelectedDate('all')}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 whitespace-nowrap
              ${selectedDate === 'all'
                ? 'bg-sky-600 text-white shadow-sm'
                : 'text-gray-700 hover:bg-gray-100'}`}
          >
            전체
          </button>
          {datesWithReservations.map(date => (
            <button
              key={date.id}
              onClick={() => setSelectedDate(date.date)}
              className={`px-4 py-2 rounded-lg transition-colors duration-200 whitespace-nowrap
                ${selectedDate === date.date
                  ? 'bg-sky-600 text-white shadow-sm'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              {format(new Date(date.date), 'M월 d일 (E)', { locale: ko })}
            </button>
          ))}
        </div>
      </Card>

      <div className="space-y-6">
        {selectedDate === 'all' ? (
          datesWithReservations.map(date => (
            <DateCard
              key={date.id}
              date={date.date}
              tours={pendingReservations[date.date]?.tours || []}
              meetings={pendingReservations[date.date]?.meetings || []}
              cancelRequests={cancelRequests[date.date] || { tours: [], meetings: [] }}
              onApprove={onApprove}
              onReject={onReject}
              onCancelRequest={onCancelRequest}
            />
          ))
        ) : (
          <DateCard
            date={selectedDate}
            tours={pendingReservations[selectedDate]?.tours || []}
            meetings={pendingReservations[selectedDate]?.meetings || []}
            cancelRequests={cancelRequests[selectedDate] || { tours: [], meetings: [] }}
            onApprove={onApprove}
            onReject={onReject}
            onCancelRequest={onCancelRequest}
          />
        )}
      </div>
    </div>
  );
}
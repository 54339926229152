import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";

export default function CancelModal({ 
  open, 
  onOpenChange, 
  onConfirm, 
  reservationType,
  isSubmitting = false 
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const cancelNote = formData.get('cancelNote');
    onConfirm(cancelNote);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>예약 취소</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="py-4 space-y-4">
            <div>
              <p className="text-gray-700">
                {reservationType === 'VIP_TOUR' ? 'VIP 투어' : '미팅룸'} 예약을 취소하시겠습니까?
              </p>
              <p className="mt-2 text-sm text-gray-500">
                취소 후에는 복구가 불가능합니다.
              </p>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                취소 사유
              </label>
              <textarea
                name="cancelNote"
                rows={3}
                required
                className="w-full p-2 border rounded-md"
                placeholder="취소 사유를 입력해주세요"
              />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="px-4 py-2 text-sm border rounded-lg hover:bg-gray-50"
              onClick={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              닫기
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm text-white bg-red-600 rounded-lg hover:bg-red-700 disabled:bg-gray-400"
              disabled={isSubmitting}
            >
              {isSubmitting ? '취소 중...' : '예약 취소'}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
import { ChevronLeft } from 'lucide-react';

export default function PageHeader({ title, onBack }) {
  return (
    <header className="flex items-center mb-6">
      <button
        onClick={onBack}
        className="p-2 mr-2 rounded-full hover:bg-gray-100"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <h1 className="text-xl font-bold">{title}</h1>
    </header>
  );
}
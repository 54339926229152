export default function VisitorForm({ 
  visitorInfo, 
  onChange, 
  type = 'tour'
}) {
  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[^\d-]/g, '');
    const numbers = value.replace(/-/g, '');
    
    if (numbers.length <= 11) {
      let formattedNumber = '';
      if (numbers.length > 3) {
        formattedNumber += numbers.substring(0, 3) + '-';
        if (numbers.length > 7) {
          formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
        } else {
          formattedNumber += numbers.substring(3);
        }
      } else {
        formattedNumber = numbers;
      }
      onChange({ ...visitorInfo, phone: formattedNumber });
    }
  };

  const labels = {
    tour: {
      company: "방문자 대표명/직함",
      name: "현장 컨택 실무자명/직함",
      phone: "현장 컨택 실무자 휴대번호",
      count: "방문 인원",
      showCountDesc: true
    },
    room: {
      company: "회사명",
      name: "방문자명",
      phone: "연락처",
      count: "방문인원",
      showCountDesc: false
    }
  };

  const currentLabels = labels[type];

  return (
    <div className="space-y-4">
      {type === 'room' && (
        <div>
          <label className="block mb-1 text-sm font-medium">미팅 목적</label>
          <input
            type="text"
            value={visitorInfo.title || ''}
            onChange={(e) => onChange({ ...visitorInfo, title: e.target.value })}
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
            required
          />
        </div>
      )}
      <div>
        <label className="block mb-1 text-sm font-medium">{currentLabels.company}</label>
        <input
          type="text"
          value={visitorInfo.company}
          onChange={(e) => onChange({ ...visitorInfo, company: e.target.value })}
          placeholder={type === 'tour' ? "ex) A사 000 대표, 000 B시 시장" : ""}
          className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
          required
        />
      </div>
      <div>
        <label className="block mb-1 text-sm font-medium">{currentLabels.name}</label>
        <input
          type="text"
          value={visitorInfo.name}
          onChange={(e) => onChange({ ...visitorInfo, name: e.target.value })}
          placeholder={type === 'tour' ? "ex) A사 000 부장, 000 B시 주무관" : ""}
          className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
          required
        />
      </div>
      <div>
        <label className="block mb-1 text-sm font-medium">{currentLabels.phone}</label>
        <input
          type="text"
          value={visitorInfo.phone}
          onChange={handlePhoneChange}
          placeholder="000-0000-0000"
          className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
          required
        />
      </div>
      <div>
        <label className="block mb-1 text-sm font-medium">{currentLabels.count}</label>
        {currentLabels.showCountDesc && (
          <p className="mb-2 text-sm text-gray-500">헤드셋 준비를 위해 가급적 자세히 적어주세요.</p>
        )}
        <input
          type="number"
          min="1"
          value={visitorInfo.count}
          onChange={(e) => onChange({ ...visitorInfo, count: parseInt(e.target.value) })}
          className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
          required
        />
      </div>
    </div>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '../../components/ui/card';
import api from '../../api/axios';

const DisplayMainPage = () => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchRooms = useCallback(async () => {
    try {
      // 초기 로딩일 때만 loading 상태 변경
      if (isInitialLoad) {
        setLoading(true);
      }

      const response = await api.get('/api/display/rooms/');
      
      // 데이터 변경 여부 확인 후 업데이트
      setRooms(prevRooms => {
        if (JSON.stringify(prevRooms) === JSON.stringify(response.data)) {
          return prevRooms; // 변경사항 없으면 이전 데이터 유지
        }
        return response.data; // 변경사항 있으면 새 데이터로 업데이트
      });
      
      setError(null);
    } catch (error) {
      console.error('Failed to fetch rooms:', error);
      // 초기 로딩일 때만 에러 상태 표시
      if (isInitialLoad) {
        setError('회의실 정보를 불러오는데 실패했습니다.');
      }
    } finally {
      if (isInitialLoad) {
        setIsInitialLoad(false);
        setLoading(false);
      }
    }
  }, [isInitialLoad]);

  useEffect(() => {
    fetchRooms();
    const interval = setInterval(fetchRooms, 30000);
    return () => clearInterval(interval);
  }, [fetchRooms]);

  // 초기 로딩 시에만 로딩 화면 표시
  if (loading && isInitialLoad) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  // 초기 로딩 시에만 에러 화면 표시
  if (error && isInitialLoad) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <div className="mx-auto max-w-7xl">
        <h1 className="mb-8 text-3xl font-bold text-center">미팅룸 현황판</h1>
        
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {rooms.map((room) => (
            <Link key={room.id} to={`/display/room/${room.id}`}>
              <Card className="transition-shadow hover:shadow-lg">
                <CardContent className="p-6">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-bold">{room.name}</h2>
                    <span className="text-sm text-gray-600">
                      {room.has_available_slots ? '예약 가능' : '예약 불가'}
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    수용 인원: {room.size}명
                  </p>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisplayMainPage;
import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Trash2, Calendar, CalendarRange } from 'lucide-react';

export default function DateManagement() {
  const [dates, setDates] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [isLoading, setIsLoading] = useState({
    single: false,
    bulk: false
  });

  useEffect(() => {
    fetchDates();
  }, []);

  const fetchDates = async () => {
    try {
      const response = await api.get('/api/admin/event-dates/');
      setDates(response.data);
      setSelectedDates([]); // 데이터를 새로 불러올 때 선택 초기화
    } catch (error) {
      toast.error('날짜 목록을 불러오는데 실패했습니다.');
      console.error('Error fetching dates:', error);
    }
  };

  const validateDates = (start, end = null) => {
    const today = new Date().toISOString().split('T')[0];
    
    if (start < today) {
      toast.error('시작일은 오늘 이후여야 합니다.');
      return false;
    }

    if (end) {
      if (end < start) {
        toast.error('종료일은 시작일 이후여야 합니다.');
        return false;
      }
    }

    return true;
  };

  const handleAddDate = async (e) => {
    e.preventDefault();
    if (!validateDates(startDate)) return;

    setIsLoading(prev => ({ ...prev, single: true }));
    try {
      await api.post('/api/admin/event-dates/', { date: startDate });
      await fetchDates();
      setStartDate('');
      toast.success('날짜가 성공적으로 추가되었습니다.');
    } catch (error) {
      let errorMessage = '날짜 추가에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      console.error('Error adding date:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, single: false }));
    }
  };

  const handleAddBulkDates = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error('시작일과 종료일을 모두 선택해주세요.');
      return;
    }
    
    if (!validateDates(startDate, endDate)) return;

    setIsLoading(prev => ({ ...prev, bulk: true }));
    try {
      await api.post('/api/admin/event-dates/bulk/', {
        start_date: startDate,
        end_date: endDate
      });
      await fetchDates();
      setStartDate('');
      setEndDate('');
      toast.success('날짜가 일괄적으로 추가되었습니다.');
    } catch (error) {
      let errorMessage = '날짜 일괄 추가에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      console.error('Error adding bulk dates:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, bulk: false }));
    }
  };

  const handleDeleteDate = async (date) => {
    const formattedDate = new Date(date).toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    if (!window.confirm(`정말로 ${formattedDate} 날짜를 삭제하시겠습니까?`)) {
      return;
    }

    try {
      await api.delete('/api/admin/event-dates/', { data: { date } });
      await fetchDates();
      toast.success(`${formattedDate} 날짜가 삭제되었습니다.`);
    } catch (error) {
      let errorMessage = '날짜 삭제에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      console.error('Error deleting date:', error);
    }
  };

  // 전체 선택 처리
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDates(dates.map(date => date.date));
    } else {
      setSelectedDates([]);
    }
  };

  // 개별 선택 처리
  const handleSelectDate = (date) => {
    setSelectedDates(prev => {
      if (prev.includes(date)) {
        return prev.filter(d => d !== date);
      } else {
        return [...prev, date];
      }
    });
  };

  // 선택된 날짜 일괄 삭제
  const handleBulkDelete = async () => {
    if (selectedDates.length === 0) {
      toast.error('삭제할 날짜를 선택해주세요.');
      return;
    }

    const confirmMessage = selectedDates.length === dates.length
      ? '모든 날짜를 삭제하시겠습니까?'
      : `선택한 ${selectedDates.length}개의 날짜를 삭제하시겠습니까?`;
    
    if (!window.confirm(confirmMessage)) return;

    try {
      await api.delete('/api/admin/event-dates/bulk/', {
        data: { dates: selectedDates }
      });
      await fetchDates();
      toast.success(`선택한 ${selectedDates.length}개의 날짜가 삭제되었습니다.`);
    } catch (error) {
      toast.error('날짜 일괄 삭제에 실패했습니다.');
      console.error('Error bulk deleting dates:', error);
    }
  };

  // 전체 날짜 삭제
  const handleDeleteAll = async () => {
    if (dates.length === 0) {
      toast.error('삭제할 날짜가 없습니다.');
      return;
    }

    if (!window.confirm('정말로 모든 날짜를 삭제하시겠습니까?')) return;

    try {
      await api.delete('/api/admin/event-dates/', {
        data: { delete_all: true }
      });
      await fetchDates();
      toast.success('모든 날짜가 삭제되었습니다.');
    } catch (error) {
      toast.error('전체 날짜 삭제에 실패했습니다.');
      console.error('Error deleting all dates:', error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium">날짜 관리</h2>
        <div className="flex gap-2">
          {selectedDates.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              선택 삭제 ({selectedDates.length})
            </button>
          )}
          <button
            onClick={handleDeleteAll}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            전체 삭제
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
        <div>
          <h3 className="flex items-center gap-2 mb-2 text-sm font-medium">
            <Calendar className="w-4 h-4" />
            단일 날짜 추가
          </h3>
          <form onSubmit={handleAddDate} className="flex gap-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="flex-1 px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <button
              type="submit"
              disabled={isLoading.single}
              className={`px-4 py-2 text-white rounded flex items-center gap-2
                ${isLoading.single 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
                }`}
            >
              {isLoading.single ? '추가 중...' : '추가'}
            </button>
          </form>
        </div>

        <div>
          <h3 className="flex items-center gap-2 mb-2 text-sm font-medium">
            <CalendarRange className="w-4 h-4" />
            기간 일괄 추가
          </h3>
          <form onSubmit={handleAddBulkDates} className="flex gap-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="flex-1 px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="flex-1 px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <button
              type="submit"
              disabled={isLoading.bulk}
              className={`px-4 py-2 text-white rounded flex items-center gap-2
                ${isLoading.bulk 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
                }`}
            >
              {isLoading.bulk ? '추가 중...' : '추가'}
            </button>
          </form>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedDates.length === dates.length && dates.length > 0}
                  onChange={handleSelectAll}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                날짜
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                작업
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {dates.length === 0 ? (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center text-gray-500">
                  등록된 날짜가 없습니다.
                </td>
              </tr>
            ) : (
              dates.map((date) => (
                <tr key={date.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedDates.includes(date.date)}
                      onChange={() => handleSelectDate(date.date)}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(date.date).toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <button
                      onClick={() => handleDeleteDate(date.date)}
                      className="p-1 text-red-600 rounded hover:text-red-800 hover:bg-red-50"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
import { Navigate } from 'react-router-dom';
import MobileLayout from '../user/MobileLayout';

export const UserRoute = ({ children }) => {
  // 세션 스토리지에서 사용자 데이터 확인
  const userData = sessionStorage.getItem('user_data');
  const userId = sessionStorage.getItem('user_id');

  // 로그인 상태가 아니면 로그인 페이지로 리다이렉트
  if (!userId || !userData) {
    return <Navigate to="/" replace />;
  }

  // 로그인 상태면 children 렌더링
  return <MobileLayout>{children}</MobileLayout>;
};

export const AdminRoute = ({ children }) => {
  const adminId = sessionStorage.getItem('admin_id');
  const adminData = sessionStorage.getItem('admin_data');

  if (!adminId || !adminData) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};
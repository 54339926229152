import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Lock } from 'lucide-react';

export default function ResourceAvailabilityManagement({ type }) {
  // 기본 상태 관리
  const [resources, setResources] = useState([]); // 도슨트 또는 미팅룸 목록
  const [dates, setDates] = useState([]); // 날짜 목록
  const [selectedResource, setSelectedResource] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [allAvailableSlots, setAllAvailableSlots] = useState([]);
  const [groupedByDate, setGroupedByDate] = useState({});
  const [searchResource, setSearchResource] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [selectedSlots, setSelectedSlots] = useState([]);
  
  // 시간 생성 관련 상태
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [interval, setInterval] = useState(type === 'tour' ? 30 : 10);
  const [duration, setDuration] = useState(type === 'tour' ? 30 : 10);
  const [isLoading, setIsLoading] = useState(false);

  const handleSlotSelection = (slotId) => {
    setSelectedSlots(prev => {
      if (prev.includes(slotId)) {
        return prev.filter(id => id !== slotId);
      }
      return [...prev, slotId];
    });
  };

  const handleLockToggle = async (action) => {
    if (selectedSlots.length === 0) {
      toast.error('선택된 시간대가 없습니다.');
      return;
    }

    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/'
        : '/api/admin/available-rooms/';

      await api.patch(endpoint, {
        time_slot_ids: selectedSlots,
        action: action
      });

      toast.success(`선택된 시간대가 ${action === 'lock' ? '잠금' : '해제'} 처리되었습니다.`);
      setSelectedSlots([]); // 선택 초기화
      await fetchAllAvailableSlots(); // 목록 새로고침
    } catch (error) {
      console.error('Error toggling lock:', error);
      toast.error(`시간대 ${action === 'lock' ? '잠금' : '해제'}에 실패했습니다.`);
    }
  };


  // 초기 데이터 로드
  useEffect(() => {
    fetchResources();
    fetchDates();
    fetchAllAvailableSlots();
  }, [type]);

  // 검색 조건이 변경될 때마다 필터링
  useEffect(() => {
    const filtered = allAvailableSlots.reduce((acc, slot) => {
      const resource = type === 'tour' ? slot.docent : slot.room;
      const date = type === 'tour' ? slot.event_date.date : slot.event_date.date;
      
      if (searchResource && resource.id.toString() !== searchResource) return acc;
      if (searchDate && date !== searchDate) return acc;
      
      if (!acc[date]) {
        acc[date] = {};
      }
      
      if (!acc[date][resource.name]) {
        acc[date][resource.name] = {
          resourceId: resource.id,
          floor: type === 'tour' ? null : resource.floor,
          size: type === 'tour' ? null : resource.size,
          slots: []
        };
      }
      
      acc[date][resource.name].slots.push({
        id: slot.id,
        time: slot.time,
        duration: slot.duration,
        is_locked: slot.is_locked,
        available: slot.available
      });
      
      acc[date][resource.name].slots.sort((a, b) => a.time.localeCompare(b.time));
      
      return acc;
    }, {});
  
    const orderedDates = Object.keys(filtered)
      .sort()
      .reduce((acc, date) => {
        acc[date] = Object.keys(filtered[date])
          .sort()
          .reduce((resourceAcc, resourceName) => {
            resourceAcc[resourceName] = filtered[date][resourceName];
            return resourceAcc;
          }, {});
        return acc;
      }, {});
  
    setGroupedByDate(orderedDates);
  }, [searchResource, searchDate, allAvailableSlots]);

  // API 호출 함수들
  const fetchResources = async () => {
    try {
      const endpoint = type === 'tour' ? '/api/admin/docents/' : '/api/admin/rooms/';
      const response = await api.get(endpoint);
      setResources(response.data);
    } catch (error) {
      console.error('Error fetching resources:', error);
      toast.error('목록을 불러오는데 실패했습니다.');
    }
  };

  const fetchDates = async () => {
    try {
      const response = await api.get('/api/admin/event-dates/');
      setDates(response.data);
    } catch (error) {
      console.error('Error fetching dates:', error);
      toast.error('날짜 목록을 불러오는데 실패했습니다.');
    }
  };

  const fetchAllAvailableSlots = async () => {
    try {
      const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
      const response = await api.get(endpoint);
      setAllAvailableSlots(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      toast.error('가용 시간 목록을 불러오는데 실패했습니다.');
    }
  };

  // 가용 시간 생성 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // 시간 유효성 검사
    if (startTime >= endTime) {
      toast.error('시작 시간은 종료 시간보다 빨라야 합니다.');
      setIsLoading(false);
      return;
    }

    if (!selectedResource || !selectedDate) {
      toast.error('리소스와 날짜를 선택해주세요.');
      setIsLoading(false);
      return;
    }

    try {
      const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
      const dateId = dates.find(d => d.date === selectedDate)?.id;

      if (!dateId) {
        toast.error('유효하지 않은 날짜입니다.');
        return;
      }

      await api.post(endpoint, {
        [`${type === 'tour' ? 'docent' : 'room'}_id`]: selectedResource,
        event_date_id: dateId,
        start_time: startTime,
        end_time: endTime,
        interval_minutes: parseInt(interval),
        duration: parseInt(duration),
      });

      toast.success('가용 시간이 설정되었습니다.');
      await fetchAllAvailableSlots();
    } catch (error) {
      toast.error('가용 시간 설정에 실패했습니다.');
      console.error('Error creating availability:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 삭제 처리 함수들
  const handleDeleteSlot = async (id) => {
    if (!window.confirm('선택한 가용 시간을 삭제하시겠습니까?')) return;

    try {
      const endpoint = type === 'tour' 
        ? `/api/admin/available-tours/${id}/` 
        : `/api/admin/available-rooms/${id}/`;
      
      await api.delete(endpoint);
      toast.success('가용 시간이 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting slot:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };

  const handleDeleteResourceSlots = async (resourceId, date) => {
    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/' 
        : '/api/admin/available-rooms/';
      
      const params = {
        [type === 'tour' ? 'docent_id' : 'room_id']: resourceId,
        date: date
      };

      await api.delete(endpoint, { params });
      toast.success('가용 시간이 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting resource slots:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };

  const handleDeleteFilteredSlots = async () => {
    const message = searchResource || searchDate
      ? '필터링된 가용 시간을 모두 삭제하시겠습니까?'
      : '모든 가용 시간을 삭제하시겠습니까?';

    if (!window.confirm(message)) return;

    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/' 
        : '/api/admin/available-rooms/';
      
      const params = {};
      if (searchResource) {
        params[type === 'tour' ? 'docent_id' : 'room_id'] = searchResource;
      }
      if (searchDate) {
        params.date = searchDate;
      }

      await api.delete(endpoint, { params });
      toast.success('선택된 가용 시간이 모두 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting filtered slots:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };

  return (
    <div className="space-y-6">
      {/* 가용 시간 생성 폼 섹션 */}
      <div className="p-6 bg-white rounded-lg shadow">
        <h2 className="mb-6 text-xl font-semibold">
          {type === 'tour' ? '도슨트 가용 시간 생성' : '미팅룸 가용 시간 생성'}
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                {type === 'tour' ? '도슨트' : '미팅룸'} 선택
              </label>
              <select
                value={selectedResource}
                onChange={(e) => setSelectedResource(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                required
              >
                <option value="">선택해주세요</option>
                {resources.map((resource) => (
                  <option key={resource.id} value={resource.id}>
                    {type === 'tour' 
                      ? resource.name 
                      : `${resource.name} (${resource.floor}층 ${resource.size}인실)`}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                날짜 선택
              </label>
              <select
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                required
              >
                <option value="">선택해주세요</option>
                {dates.map((date) => (
                  <option key={date.id} value={date.date}>
                    {date.date}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                시작 시간
              </label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                종료 시간
              </label>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                {type === 'tour' ? '예약 간격' : '시간 간격'} (분)
              </label>
              <input
                type="number"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                min="1"
                required
              />
            </div>
            {type === 'tour' && (
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                투어 소요시간 (분)
              </label>
              <input
                type="number"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                min="1"
                required
              />
            </div>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isLoading}
              className={`px-6 py-2 text-white rounded-md ${
                isLoading 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {isLoading ? '생성 중...' : '가용 시간 생성'}
            </button>
          </div>
        </form>
      </div>

      {/* 가용 시간 목록 섹션 */}
      <div className="p-6 bg-white rounded-lg shadow">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-medium">설정된 가용 시간 목록</h3>
            {selectedSlots.length > 0 && (
              <span className="text-sm text-gray-500">
                {selectedSlots.length}개 선택됨
              </span>
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex gap-2">
              <select
                value={searchResource}
                onChange={(e) => setSearchResource(e.target.value)}
                className="px-4 py-2 border rounded-md"
              >
                <option value="">전체 {type === 'tour' ? '도슨트' : '미팅룸'}</option>
                {resources.map((resource) => (
                  <option key={resource.id} value={resource.id}>
                    {resource.name}
                  </option>
                ))}
              </select>
              <select
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                className="px-4 py-2 border rounded-md"
              >
                <option value="">전체 날짜</option>
                {dates.map((date) => (
                  <option key={date.id} value={date.date}>
                    {date.date}
                  </option>
                ))}
              </select>
            </div>
            {selectedSlots.length > 0 ? (
              <div className="flex gap-2">
                <button
                  onClick={() => handleLockToggle('unlock')}
                  className="px-4 py-2 text-blue-600 transition-colors rounded-md bg-blue-50 hover:bg-blue-100"
                >
                  해제
                </button>
                <button
                  onClick={() => handleLockToggle('lock')}
                  className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  잠금
                </button>
              </div>
            ) : (
              <button
                onClick={handleDeleteFilteredSlots}
                className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                {searchResource || searchDate ? '필터 항목 삭제' : '전체 삭제'}
              </button>
            )}
          </div>
        </div>


        <div className="space-y-8">
          {Object.keys(groupedByDate).length > 0 ? (
            Object.entries(groupedByDate).map(([date, resources]) => (
              <div key={date} className="p-4 border rounded-lg">
                <h4 className="pb-2 mb-4 text-lg font-medium border-b">{date}</h4>
                <div className="space-y-6">
                  {Object.entries(resources).map(([resourceName, data]) => (
                    <div key={`${date}_${resourceName}`} className="p-4 ml-4 rounded-lg bg-gray-50">
                      <div className="flex items-center justify-between mb-3">
                        <h5 className="font-semibold text-md">
                          {type === 'tour' 
                            ? resourceName 
                            : `${resourceName} (${data.floor}층 ${data.size}인실)`}
                        </h5>
                        <button
                          onClick={() => handleDeleteResourceSlots(data.resourceId, date)}
                          className="text-sm text-red-600 hover:text-red-800"
                        >
                          전체 삭제
                        </button>
                      </div>
                      <div className="grid grid-cols-6 gap-2">
                        {data.slots.map((slot) => (
                          <div
                          key={slot.id}
                          className="relative group"
                          onClick={() => handleSlotSelection(slot.id)}
                        >
                          <div className={`
                            px-3 py-2 text-sm text-center rounded shadow-sm cursor-pointer
                            ${slot.is_locked 
                              ? 'bg-gray-200' // 잠긴 상태
                              : !slot.available 
                                ? 'bg-sky-50' // 예약된 상태 (하늘색)
                                : 'bg-white hover:bg-gray-50' // 일반 상태
                            }
                            ${selectedSlots.includes(slot.id) ? 'ring-2 ring-blue-500' : ''}
                          `}>
                            {slot.is_locked && (
                              <div className="absolute top-1 left-1">
                                <Lock size={14} className="text-red-500" />
                              </div>
                            )}
                            <div className={`mt-1 ${slot.is_locked && 'ml-5'}`}>
                              {slot.time}
                              {type === 'tour' && (
                                <div className="text-xs text-gray-500">
                                  ({slot.duration}분)
                                </div>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteSlot(slot.id);
                            }}
                            className="absolute top-0 right-0 hidden p-1 -mt-2 -mr-2 text-xs text-red-600 bg-white rounded-full shadow-sm group-hover:block hover:text-red-800"
                          >
                            ×
                          </button>
                        </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="py-4 text-center text-gray-500">
              {searchResource || searchDate ? '검색 결과가 없습니다.' : '설정된 가용 시간이 없습니다.'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { 
  CircleIcon, 
  Building2Icon, 
  Users2Icon, 
  ClockIcon, 
  AlertCircleIcon, 
  CheckIcon 
} from "lucide-react";

export default function DashboardStats({ stats = {} }) {
  const statCards = [
    {
      title: "오늘의 투어",
      value: stats.today_tours || 0,
      icon: <Users2Icon className="w-4 h-4 text-sky-600" />,
      color: "text-sky-600",
      bgColor: "bg-sky-50"
    },
    {
      title: "오늘의 미팅",
      value: stats.today_meetings || 0,
      icon: <Building2Icon className="w-4 h-4 text-emerald-600" />,
      color: "text-emerald-600",
      bgColor: "bg-emerald-50"
    },
    {
      title: "대기중인 예약",
      value: (stats.pending_tours || 0) + (stats.pending_meetings || 0),
      subValues: {
        tours: stats.pending_tours || 0,
        meetings: stats.pending_meetings || 0
      },
      icon: <ClockIcon className="w-4 h-4 text-orange-600" />,
      color: "text-orange-600",
      bgColor: "bg-orange-50"
    },
    {
      title: "취소 요청",
      value: (stats.cancel_requested_tours || 0) + (stats.cancel_requested_meetings || 0),
      subValues: {
        tours: stats.cancel_requested_tours || 0,
        meetings: stats.cancel_requested_meetings || 0
      },
      icon: <AlertCircleIcon className="w-4 h-4 text-yellow-600" />,
      color: "text-yellow-600",
      bgColor: "bg-yellow-50"
    },
    {
      title: "완료된 예약",
      value: (stats.completed_tours || 0) + (stats.completed_meetings || 0),
      subValues: {
        tours: stats.completed_tours || 0,
        meetings: stats.completed_meetings || 0
      },
      icon: <CheckIcon className="w-4 h-4 text-green-600" />,
      color: "text-green-600",
      bgColor: "bg-green-50"
    }
  ];

  return (
    <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
      {statCards.map((stat, index) => (
        <Card key={index} className="transition-all duration-200 hover:shadow-md">
          <CardHeader className="flex flex-row items-center justify-between space-y-0">
            <CardTitle 
              className="text-sm font-medium text-gray-600"
              aria-label={`${stat.title} 통계`}
            >
              {stat.title}
            </CardTitle>
            <div className={`p-2 rounded-full ${stat.bgColor}`}>
              {stat.icon}
            </div>
          </CardHeader>
          <CardContent>
            <div className={`text-2xl font-bold ${stat.value === 0 ? 'text-gray-400' : stat.color}`}>
              {stat.value.toLocaleString()}
            </div>
            {stat.subValues && (
              <div className="mt-1 text-xs text-gray-500">
                <span className="inline-flex items-center">
                  <CircleIcon className="w-2 h-2 mr-1 text-sky-600" />
                  투어: {stat.subValues.tours.toLocaleString()}
                </span>
                <span className="mx-2">·</span>
                <span className="inline-flex items-center">
                  <CircleIcon className="w-2 h-2 mr-1 text-emerald-600" />
                  미팅: {stat.subValues.meetings.toLocaleString()}
                </span>
              </div>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
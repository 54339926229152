import { useState, useEffect, useCallback, useMemo } from 'react';
import api from '../api/axios';

// API URL 상수
const ADMIN_API = {
  EVENT_DATES: '/api/admin/event-dates/',
  DOCENTS: '/api/admin/docents/',
  ROOMS: '/api/admin/rooms/',
  STATS: '/api/admin/dashboard/stats/',
  RESERVATIONS: '/api/admin/reservations/'
};

export function useAdminDashboard(pollingInterval = 30000) {
  const [data, setData] = useState({
    dates: [],
    docents: [],
    rooms: [],
    approvedReservations: {},
    pendingReservations: {},
    cancelRequests: {},
    completedReservations: {},
    rejectedReservations: {},
    cancelledReservations: {},
    stats: {
      pending_tours: 0,
      pending_meetings: 0,
      cancel_requested_tours: 0,
      cancel_requested_meetings: 0,
      today_tours: 0,
      today_meetings: 0,
      completed_tours: 0,
      completed_meetings: 0,
    },
    loading: true,
    error: null,
    lastUpdated: null
  });

  const processReservations = useCallback((dates, tours, meetings) => {
    const approved = {};
    const pending = {};
    const cancelRequests = {};
    const completed = {};
    const rejected = {}; 
    const cancelled = {};

    dates.forEach(date => {
      const dateStr = date.date;
      
      // VIP 투어의 경우 중첩된 객체 구조 사용
      const filteredTours = {
        approved: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'APPROVED'
        ),
        pending: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'PENDING'
        ),
        cancelRequested: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'CANCEL_REQUESTED'
        ),
        completed: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'COMPLETED'
        ),
        rejected: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'REJECTED'
        ),
        cancelled: tours.filter(tour => 
          tour.available_tour.event_date.date === dateStr && 
          tour.status === 'CANCELLED'
        )
      };

      // 회의실 예약의 경우 event_date 직접 접근
      const filteredMeetings = {
        approved: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'APPROVED'
        ),
        pending: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'PENDING'
        ),
        cancelRequested: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'CANCEL_REQUESTED'
        ),
        completed: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'COMPLETED'
        ),
        rejected: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'REJECTED'
        ),
        cancelled: meetings.filter(meeting => 
          meeting.event_date.date === dateStr && 
          meeting.status === 'CANCELLED'
        )
      };

      // 각 상태별로 투어와 회의실 예약 할당
      approved[dateStr] = {
        tours: filteredTours.approved,
        meetings: filteredMeetings.approved
      };

      pending[dateStr] = {
        tours: filteredTours.pending,
        meetings: filteredMeetings.pending
      };

      cancelRequests[dateStr] = {
        tours: filteredTours.cancelRequested,
        meetings: filteredMeetings.cancelRequested
      };

      completed[dateStr] = {
        tours: filteredTours.completed,
        meetings: filteredMeetings.completed
      };

      rejected[dateStr] = {
        tours: filteredTours.rejected,
        meetings: filteredMeetings.rejected
      };

      cancelled[dateStr] = {
        tours: filteredTours.cancelled,
        meetings: filteredMeetings.cancelled
      };
    });

    return { 
      approved, 
      pending, 
      cancelRequests, 
      completed, 
      rejected, 
      cancelled 
    };
  }, []);

  const fetchDashboardData = useCallback(async (showLoading = true) => {
    try {
      if (showLoading) {
        setData(prev => ({ ...prev, loading: true, error: null }));
      }
  
      const [datesRes, docentsRes, roomsRes, statsRes, reservationsRes] = 
        await Promise.all([
          api.get(ADMIN_API.EVENT_DATES),
          api.get(ADMIN_API.DOCENTS),
          api.get(ADMIN_API.ROOMS),
          api.get(ADMIN_API.STATS),
          api.get(ADMIN_API.RESERVATIONS)
        ]);
  
      const { tours, meetings } = reservationsRes.data;
      const { 
        approved, 
        pending, 
        cancelRequests, 
        completed, 
        rejected, 
        cancelled
      } = processReservations(
        datesRes.data, 
        tours, 
        meetings
      );
  
      setData({
        dates: datesRes.data,
        docents: docentsRes.data,
        rooms: roomsRes.data,
        approvedReservations: approved,
        pendingReservations: pending,
        cancelRequests: cancelRequests,
        completedReservations: completed,
        rejectedReservations: rejected,
        cancelledReservations: cancelled,
        stats: statsRes.data,
        loading: false,
        error: null,
        lastUpdated: new Date()
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setData(prev => ({
        ...prev,
        loading: false,
        error: error.response?.data?.error || '데이터를 불러오는데 실패했습니다.',
        lastUpdated: prev.lastUpdated
      }));
    }
  }, [processReservations]);

  const hasPendingActions = useMemo(() => {
    return {
      pendingReservations: Object.values(data.pendingReservations).some(
        date => date.tours.length > 0 || date.meetings.length > 0
      ),
      cancelRequests: Object.values(data.cancelRequests).some(
        date => date.tours.length > 0 || date.meetings.length > 0
      )
    };
  }, [data.pendingReservations, data.cancelRequests]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDashboardData(false);
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [fetchDashboardData, pollingInterval]);

  return {
    ...data,
    hasPendingActions,
    refreshData: () => fetchDashboardData(true)
  };
}
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarDays, ClipboardList, LogOut, User } from 'lucide-react';
import api from '../../api/axios';

// 하단 네비게이션 컴포넌트
function BottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get('/api/user/my-info/');
        setUserInfo(response.data);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }

    try {
      await api.post('/api/user/logout/');
      // 세션 스토리지 클리어
      sessionStorage.clear();
      // 로그인 페이지로 이동
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
      // 에러가 발생하더라도 로컬의 세션은 클리어하고 로그인 페이지로 이동
      sessionStorage.clear();
      navigate('/', { replace: true });
    }
  };

  const navItems = [
    {
      icon: CalendarDays,
      label: '예약 신청하기',
      path: '/dashboard',
    },
    {
      icon: ClipboardList,
      label: '예약내역',
      path: '/reservations',
    },
    {
      icon: User,
      label: userInfo?.name || '내정보',
      path: '/profile',
    },
    {
      icon: LogOut,
      label: '로그아웃',
      onClick: handleLogout,
    },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t">
      <div className="container max-w-md mx-auto">
        <div className="grid grid-cols-4">
          {navItems.map((item) => {
            const Icon = item.icon;
            const isActive = item.path && location.pathname === item.path;

            return (
              <button
                key={item.label}
                onClick={item.onClick || (() => navigate(item.path))}
                className={`flex flex-col items-center py-3 transition-colors ${
                  isActive ? 'text-sky-600' : 'text-gray-600 hover:text-sky-600'
                }`}
              >
                <Icon className="w-6 h-6" />
                <span className="mt-1 text-xs">{item.label}</span>
              </button>
            );
          })}
        </div>
      </div>
    </nav>
  );
}

// 메인 레이아웃 컴포넌트
export default function MobileLayout({ children }) {
  const location = useLocation();

  // 로그인 페이지에서는 하단 네비게이션 숨김
  const hideNavigation = location.pathname === '/';

  return (
    <div className="min-h-screen bg-gray-50">

      {/* 메인 콘텐츠 */}
      <main className={`pb-${hideNavigation ? '0' : '24'}`}>
        {children}
      </main>

      {/* 하단 네비게이션 */}
      {!hideNavigation && <BottomNavigation />}
    </div>
  );
}
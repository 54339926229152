import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Trash2, Edit2, X, Check, Star } from 'lucide-react';

export default function RoomsManagement() {
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [editingRoom, setEditingRoom] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    size: '',
    floor: '',
    is_vip: false
  });
  const [editForm, setEditForm] = useState({
    name: '',
    size: '',
    floor: '',
    is_vip: false
  });

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const response = await api.get('/api/admin/rooms/');
      setRooms(response.data);
      setSelectedRooms([]);
    } catch (error) {
      toast.error('미팅룸 목록을 불러오는데 실패했습니다.');
      console.error('Error fetching rooms:', error);
    }
  };

  const validateForm = (data) => {
    if (data.name.trim().length === 0) {
      toast.error('미팅룸 이름을 입력해주세요.');
      return false;
    }
    if (parseInt(data.size) <= 0) {
      toast.error('수용 인원은 0보다 커야 합니다.');
      return false;
    }
    if (parseInt(data.size) > 999) {
      toast.error('수용 인원은 999명을 초과할 수 없습니다.');
      return false;
    }
    if (parseInt(data.floor) <= 0) {
      toast.error('층수를 입력해주세요.');
      return false;
    }
    if (parseInt(data.floor) > 999) {
      toast.error('층수는 999를 초과할 수 없습니다.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(formData)) {
      return;
    }
    
    setIsLoading(true);
    try {
      await api.post('/api/admin/rooms/', {
        name: formData.name.trim(),
        size: parseInt(formData.size),
        floor: parseInt(formData.floor),
        is_vip: formData.is_vip
      });
      await fetchRooms();
      setFormData({ name: '', size: '', floor: '', is_vip: false });
      toast.success('미팅룸이 성공적으로 추가되었습니다.');
    } catch (error) {
      let errorMessage = '미팅룸 추가에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id, name) => {
    if (!window.confirm(`정말로 "${name}" 미팅룸을 삭제하시겠습니까?`)) {
      return;
    }

    try {
      await api.delete(`/api/admin/rooms/${id}/`);
      await fetchRooms();
      toast.success(`"${name}" 미팅룸이 삭제되었습니다.`);
    } catch (error) {
      let errorMessage = '미팅룸 삭제에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    }
  };

  // 수정 모드 시작
  const handleStartEdit = (room) => {
    setEditingRoom(room.id);
    setEditForm({
      name: room.name,
      size: room.size.toString(),
      floor: room.floor.toString(),
      is_vip: room.is_vip
    });
  };

  // 수정 취소
  const handleCancelEdit = () => {
    setEditingRoom(null);
    setEditForm({ name: '', size: '', is_vip: false });
  };

  // 수정 저장
  const handleSaveEdit = async (id) => {
    if (!validateForm(editForm)) return;
  
    try {
      await api.put(`/api/admin/rooms/${id}/`, {
        name: editForm.name.trim(),
        size: parseInt(editForm.size),
        floor: parseInt(editForm.floor),
        is_vip: editForm.is_vip
      });
      await fetchRooms();
      setEditingRoom(null);
      toast.success('미팅룸 정보가 수정되었습니다.');
    } catch (error) {
      let errorMessage = '미팅룸 수정에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    }
  };

  // 전체 선택 처리
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRooms(rooms.map(room => room.id));
    } else {
      setSelectedRooms([]);
    }
  };

  // 개별 선택 처리
  const handleSelectRoom = (roomId) => {
    setSelectedRooms(prev => {
      if (prev.includes(roomId)) {
        return prev.filter(id => id !== roomId);
      } else {
        return [...prev, roomId];
      }
    });
  };

  // 선택된 미팅룸 일괄 삭제
  const handleBulkDelete = async () => {
    if (selectedRooms.length === 0) {
      toast.error('삭제할 미팅룸을 선택해주세요.');
      return;
    }

    const confirmMessage = selectedRooms.length === rooms.length
      ? '모든 미팅룸을 삭제하시겠습니까?'
      : `선택한 ${selectedRooms.length}개의 미팅룸을 삭제하시겠습니까?`;
    
    if (!window.confirm(confirmMessage)) return;

    try {
      await api.delete('/api/admin/rooms/', {
        data: { room_ids: selectedRooms }
      });
      await fetchRooms();
      toast.success(`선택한 ${selectedRooms.length}개의 미팅룸이 삭제되었습니다.`);
    } catch (error) {
      toast.error('미팅룸 일괄 삭제에 실패했습니다.');
    }
  };

  // 전체 미팅룸 삭제
  const handleDeleteAll = async () => {
    if (rooms.length === 0) {
      toast.error('삭제할 미팅룸이 없습니다.');
      return;
    }

    if (!window.confirm('정말로 모든 미팅룸을 삭제하시겠습니까?')) return;

    try {
      await api.delete('/api/admin/rooms/', {
        data: { delete_all: true }
      });
      await fetchRooms();
      toast.success('모든 미팅룸이 삭제되었습니다.');
    } catch (error) {
      toast.error('전체 미팅룸 삭제에 실패했습니다.');
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium">미팅룸 관리</h2>
        <div className="flex gap-2">
          {selectedRooms.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              선택 삭제 ({selectedRooms.length})
            </button>
          )}
          <button
            onClick={handleDeleteAll}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            전체 삭제
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="grid grid-cols-5 gap-4 mb-6">
        <input
          type="text"
          placeholder="미팅룸 이름"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          maxLength={50}
        />
        <input
          type="number"
          placeholder="층수"
          value={formData.floor}
          onChange={(e) => setFormData({ ...formData, floor: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          min="1"
          max="999"
        />
        <input
          type="number"
          placeholder="수용 인원"
          value={formData.size}
          onChange={(e) => setFormData({ ...formData, size: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          min="1"
          max="999"
        />
        <div className="flex items-center">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.is_vip}
              onChange={(e) => setFormData({ ...formData, is_vip: e.target.checked })}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">VIP 룸</span>
          </label>
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 text-white rounded flex items-center justify-center
            ${isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {isLoading ? '추가 중...' : '추가'}
        </button>
      </form>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedRooms.length === rooms.length && rooms.length > 0}
                  onChange={handleSelectAll}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                이름
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                층수
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                수용 인원
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                VIP
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                작업
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rooms.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  등록된 미팅룸이 없습니다.
                </td>
              </tr>
            ) : (
              rooms.map((room) => (
                <tr key={room.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedRooms.includes(room.id)}
                      onChange={() => handleSelectRoom(room.id)}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                  </td>
                  {editingRoom === room.id ? (
                    <>
                      <td className="px-6 py-4">
                        <input
                          type="text"
                          value={editForm.name}
                          onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                          className="w-full px-2 py-1 border rounded"
                          maxLength={50}
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={editForm.floor}
                          onChange={(e) => setEditForm({ ...editForm, floor: e.target.value })}
                          className="w-full px-2 py-1 border rounded"
                          min="1"
                          max="999"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={editForm.size}
                          onChange={(e) => setEditForm({ ...editForm, size: e.target.value })}
                          className="w-full px-2 py-1 border rounded"
                          min="1"
                          max="999"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={editForm.is_vip}
                            onChange={(e) => setEditForm({ ...editForm, is_vip: e.target.checked })}
                            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                          />
                          <span className="text-sm text-gray-700">VIP</span>
                        </label>
                      </td>
                      <td className="px-6 py-4 text-right">
                        <button
                          onClick={() => handleSaveEdit(room.id)}
                          className="p-1 mr-2 text-green-600 rounded hover:text-green-800 hover:bg-green-50"
                        >
                          <Check className="w-5 h-5" />
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="p-1 text-gray-600 rounded hover:text-gray-800 hover:bg-gray-50"
                        >
                          <X className="w-5 h-5" />
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-6 py-4 whitespace-nowrap">{room.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{room.floor}층</td>
                      <td className="px-6 py-4 whitespace-nowrap">{room.size}명</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {room.is_vip ? (
                          <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-yellow-800 bg-yellow-100 rounded-full">
                            <Star className="w-3 h-3 mr-1" />
                            VIP
                          </span>
                        ) : (
                          <span className="text-gray-400">일반</span>
                        )}
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap">
                        <button
                          onClick={() => handleStartEdit(room)}
                          className="p-1 mr-2 text-blue-600 rounded hover:text-blue-800 hover:bg-blue-50"
                        >
                          <Edit2 className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(room.id, room.name)}
                          className="p-1 text-red-600 rounded hover:text-red-800 hover:bg-red-50"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formatDate(date) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  return `${year}년 ${month}월 ${day}일`;
}

export function formatTime(time) {
  if (!time) return ''; // time이 undefined나 null인 경우 빈 문자열 반환
  
  // "09:00:00" 또는 "2024-01-01T09:00:00" 형식을 "09:00"으로 변환
  if (time.includes('T')) {
    return time.split('T')[1].substring(0, 5);
  }
  return time.substring(0, 5);
}

export function formatPhoneNumber(phone) {
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 11) {
    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return phone;
}

export function formatDateTime(date, startTime, endTime) {
  if (!date || !startTime || !endTime) return '';

  const formattedDate = formatDate(date);
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);

  return `${formattedDate} ${formattedStartTime} ~ ${formattedEndTime}`;
}

import { useState, useEffect } from 'react';
import { X, User, Users, Calendar, MapPin, Clock, FileText, Building2, Edit2 } from 'lucide-react';
import { Card } from '../../ui/card';
import { addMinutes } from 'date-fns';
import VisitorEditForm from './VisitorEditForm';
import NoteEditForm from './NoteEditForm';

const InfoItem = ({ icon: Icon, label, value }) => {
  if (!value) return null;
  
  return (
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <Icon className="w-4 h-4 text-gray-500" />
      </div>
      <div>
        <div className="text-sm text-gray-500">{label}</div>
        <div className="font-medium text-gray-900 whitespace-pre-line">{value}</div>
      </div>
    </div>
  );
};

const ReservationDetailModal = ({ isOpen, onClose, reservation: initialReservation, onCancel, refreshData }) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [reservation, setReservation] = useState(initialReservation);
  const [isNoteEditing, setIsNoteEditing] = useState(false);

  // initialReservation이 변경될 때마다 local state 업데이트
  useEffect(() => {
    setReservation(initialReservation);
  }, [initialReservation]);

  const handleClose = () => {
    setIsEditing(false);
    setIsNoteEditing(false);
    setIsCancelDialogOpen(false);
    setCancelReason('');
    onClose();
  };

  if (!isOpen || !reservation) return null;

  const {
    visitor,
    user,
    type
  } = reservation;

  const isTour = type === 'tour';

  const handleNoteUpdate = async (updatedNote) => {
    if (typeof refreshData === 'function') {
      await refreshData();
    }
    
    setReservation(prev => ({
      ...prev,
      note: updatedNote
    }));
    
    setIsNoteEditing(false);
  };

  // 시간 범위 계산
  const getTimeRange = () => {
    if (isTour) {
      const startTime = reservation.available_tour.time;
      const [hours, minutes] = startTime.split(':').map(Number);
      const startDate = new Date(2000, 0, 1, hours, minutes);
      const endDate = addMinutes(startDate, reservation.available_tour.duration);
      const endTime = endDate.toTimeString().slice(0, 5);
      return `${startTime} - ${endTime}`;
    } else {
      const timeSlots = reservation.time_slots;
      const firstSlot = timeSlots[0];
      const lastSlot = timeSlots[timeSlots.length - 1];
      const startTime = firstSlot.available_room.time;
      const [hours, minutes] = lastSlot.available_room.time.split(':').map(Number);
      const startDate = new Date(2000, 0, 1, hours, minutes);
      const endDate = addMinutes(startDate, lastSlot.duration);
      const endTime = endDate.toTimeString().slice(0, 5);
      return `${startTime} - ${endTime}`;
    }
  };

  const reservationInfo = {
    type: isTour ? 'VIP 투어' : '미팅룸',
    time: getTimeRange(),
    date: isTour 
      ? reservation.available_tour.event_date.date 
      : reservation.event_date.date,
    location: isTour
      ? `도슨트: ${reservation.available_tour.docent.name} (${reservation.available_tour.docent.language})`
      : `회의실: ${reservation.room.name} (${reservation.room.floor ? `${reservation.room.floor}층` : ''} ${reservation.room.size}인실)`
  };

  const handleCancel = () => {
    onCancel(reservation, cancelReason);
    setIsCancelDialogOpen(false);
    setCancelReason('');
    onClose();
  };

  const handleVisitorUpdate = async (updatedVisitor) => {
    // refreshData가 존재할 경우에만 실행
    if (typeof refreshData === 'function') {
      await refreshData();
    }
    
    // local state 업데이트
    setReservation(prev => ({
      ...prev,
      visitor: {
        ...prev.visitor,
        ...updatedVisitor
      }
    }));
    
    setIsEditing(false);
  };

  const getStatusBadgeStyle = (status) => {
    switch(status) {
      case 'CANCELLED':
        return 'bg-gray-100 text-gray-700';
      case 'CANCEL_REQUESTED':
        return 'bg-orange-100 text-orange-700';
      case 'APPROVED':
        return 'bg-emerald-100 text-emerald-700';
      case 'COMPLETED':
        return 'bg-blue-100 text-blue-700';
      default:
        return 'bg-yellow-100 text-yellow-700';
    }
  };

  const getStatusText = (status) => {
    switch(status) {
      case 'CANCELLED':
        return '취소됨';
      case 'CANCEL_REQUESTED':
        return '취소 요청됨';
      case 'APPROVED':
        return '승인됨';
      case 'COMPLETED':
        return '완료됨';
      default:
        return '대기중';
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-2xl bg-white flex flex-col max-h-[90vh]">
        {/* 헤더 - 고정 */}
        <div className="flex items-center justify-between p-6 border-b shrink-0">
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">예약 상세 정보</h3>
          </div>
          <div className="flex items-center gap-2">
            {['APPROVED', 'PENDING'].includes(reservation.status) && (
              <button
                onClick={() => setIsCancelDialogOpen(true)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
              >
                예약 취소
              </button>
            )}
            <button
              onClick={handleClose}
              className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* 본문 - 스크롤 가능 */}
        <div 
          className="flex-1 p-6 space-y-6 overflow-y-auto"
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#E5E7EB transparent'
          }}
        >
          <style jsx global>{`
            .overflow-y-auto::-webkit-scrollbar {
              width: 6px;
            }
            
            .overflow-y-auto::-webkit-scrollbar-track {
              background: transparent;
            }
            
            .overflow-y-auto::-webkit-scrollbar-thumb {
              background-color: #E5E7EB;
              border-radius: 20px;
              border: 2px solid transparent;
            }

            .overflow-y-auto::-webkit-scrollbar-thumb:hover {
              background-color: #D1D5DB;
            }
          `}</style>

          {/* 예약 상태 */}
          <div className="flex items-center">
            <div className={`px-3 py-1 text-sm font-medium rounded-full ${getStatusBadgeStyle(reservation.status)}`}>
              {getStatusText(reservation.status)}
            </div>
            <div className={`px-3 py-1 text-sm font-medium rounded-full 
              ${isTour ? 'bg-sky-100 text-sky-700' : 'bg-emerald-100 text-emerald-700'}`}
            >
              {reservationInfo.type}
            </div>
          </div>

          {/* 예약 기본 정보 */}
          <Card className="p-4 bg-sky-50 border-sky-100">
            <div className="grid grid-cols-2 gap-4">
              <InfoItem 
                icon={Calendar} 
                label="예약 일자" 
                value={reservationInfo.date} 
              />
              <InfoItem 
                icon={Clock} 
                label="예약 시간" 
                value={reservationInfo.time} 
              />
              <div className="col-span-2">
                <InfoItem 
                  icon={MapPin} 
                  label={isTour ? '도슨트 정보' : '장소 정보'} 
                  value={reservationInfo.location} 
                />
              </div>
            </div>
          </Card>

          {/* 신청자 및 방문자 정보 */}
          <div className="grid gap-6 md:grid-cols-2">
            {/* 신청자 정보 */}
            <div className="space-y-4">
              <h4 className="text-sm font-semibold text-gray-900">신청자 정보</h4>
              <Card className="p-4 space-y-4 border">
                <InfoItem icon={User} label="이름" value={user.name} />
                <InfoItem icon={Building2} label="회사명" value={user.company_name} />
                <InfoItem icon={User} label="직책" value={user.position} />
                <InfoItem icon={User} label="연락처" value={user.phone} />
              </Card>
            </div>

            {/* 방문자 정보 */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h4 className="text-sm font-semibold text-gray-900">방문자 정보</h4>
                {!isEditing && (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="flex items-center px-2 py-1 text-xs border rounded text-sky-600 border-sky-300 hover:bg-sky-50"
                  >
                    <Edit2 className="w-3 h-3 mr-1" />
                    수정
                  </button>
                )}
              </div>
              {isEditing ? (
                <VisitorEditForm
                  visitor={visitor}
                  reservationType={type}
                  reservationId={reservation.id}
                  onSuccess={handleVisitorUpdate}
                  onCancel={() => setIsEditing(false)}
                  maxSize={isTour ? 30 : reservation.room?.size}
                />
              ) : (
                <Card className="p-4 space-y-4 border">
                  {!isTour && (
                    <InfoItem icon={FileText} label="미팅 목적" value={visitor.title} />
                  )}
                  <InfoItem 
                    icon={User} 
                    label={isTour ? "현장 컨택 실무자명/직함" : "방문자명"} 
                    value={visitor.name} 
                  />
                  <InfoItem 
                    icon={Building2} 
                    label={isTour ? "방문자 대표명/직함" : "회사명"} 
                    value={visitor.company} 
                  />
                  <InfoItem 
                    icon={User} 
                    label={isTour ? "현장 컨택 실무자 휴대번호" : "연락처"} 
                    value={visitor.phone} 
                  />
                  <InfoItem 
                    icon={Users} 
                    label="방문 인원" 
                    value={`${visitor.count}명`} 
                  />
                </Card>
              )}
            </div>
          </div>

          {/* 요청사항 및 추가 정보 */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-semibold text-gray-900">추가 정보</h4>
              {!isNoteEditing && (
                <button
                  onClick={() => setIsNoteEditing(true)}
                  className="flex items-center px-2 py-1 text-xs border rounded text-sky-600 border-sky-300 hover:bg-sky-50"
                >
                  <Edit2 className="w-3 h-3 mr-1" />
                  {reservation.note ? '수정' : '추가'}
                </button>
              )}
            </div>
            <Card className="p-4 space-y-4 border">
              {isNoteEditing ? (
                <NoteEditForm
                  note={reservation.note}
                  reservationType={type}
                  reservationId={reservation.id}
                  onSuccess={handleNoteUpdate}
                  onCancel={() => setIsNoteEditing(false)}
                />
              ) : (
                <>
                  {reservation.note && (
                    <InfoItem 
                      icon={FileText} 
                      label={isTour ? "상세 정보" : "요청사항"} 
                      value={reservation.note} 
                    />
                  )}
                  {!reservation.note && !reservation.feedback && !reservation.cancel_note && (
                    <div className="py-2 text-sm text-center text-gray-500">
                      입력된 정보가 없습니다
                    </div>
                  )}
                  {reservation.feedback && (
                    <InfoItem 
                      icon={FileText} 
                      label="피드백" 
                      value={reservation.feedback} 
                    />
                  )}
                  {reservation.cancel_note && (
                    <InfoItem 
                      icon={FileText} 
                      label="취소 사유" 
                      value={reservation.cancel_note} 
                    />
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      </Card>

      {/* 취소 다이얼로그 */}
      {isCancelDialogOpen && (
        <div className="fixed inset-0 z-[60] flex items-center justify-center p-4 bg-gray-900/80">
          <Card className="w-full max-w-md p-6 bg-white">
            <h4 className="mb-4 text-lg font-medium">예약 취소</h4>
            <p className="mb-4 text-sm text-gray-600">
              이 예약을 취소하시겠습니까? 취소 사유를 입력해주세요.
            </p>
            <textarea
              className="w-full p-2 mb-4 border rounded-md"
              rows={4}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              placeholder="취소 사유를 입력하세요..."
              required
            />
            <div className="flex justify-end gap-2">
              <button
                className="px-4 py-2 text-sm border rounded-md hover:bg-gray-50"
                onClick={() => setIsCancelDialogOpen(false)}
              >
                닫기
              </button>
              <button
                className="px-4 py-2 text-sm text-white bg-red-600 rounded-md hover:bg-red-700"
                onClick={handleCancel}
                disabled={!cancelReason.trim()}
              >
                취소 확인
              </button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ReservationDetailModal;